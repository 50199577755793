import { Table } from "flowbite-react"
import { TableHeadCellProps } from "flowbite-react/lib/esm/components/Table/TableHeadCell";
import { useState } from "react";
import { FaArrowUp, FaArrowDown } from 'react-icons/fa/index'

interface SortableTableHeadCellProps {
    title: string;
    isSorted: boolean;
    sortDirection: 'asc' | 'desc';
    onSortColumn?: () => void;
}

const SortableTableHeadCell : React.FC<SortableTableHeadCellProps & TableHeadCellProps> = ({title, isSorted, sortDirection, onSortColumn, ...props}) => {
    const [_sortDirection, _setSortDirection] = useState<'asc' | 'desc'>(sortDirection);

    const onCellClick = () => {
        onSortColumn?.call(this);
        _setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    }

    return (
        <Table.HeadCell {...props} onClick={() => onCellClick()}>
            {title}
            {isSorted && _sortDirection === 'asc' ? (<FaArrowUp className="inline ml-2"/>) : (<></>)} {isSorted && _sortDirection === 'desc' ? (<FaArrowDown className="inline ml-2"/>) : (<></>)}
        </Table.HeadCell>
    )
}

export default SortableTableHeadCell;