import React from "react"
import CodeUtil from "../../../util/CodeUtil"
import DataGrid from "../../DataGrid"
import { InvestmentCalculatorModalContentType } from "../types"

export const InvestmentCalculatorContent: React.FC<InvestmentCalculatorModalContentType> = (props) => {
    return (
        <React.Fragment>
            {props.data?.length === 0 ? (<></>) :
                (<div className="mt-10">
                    <DataGrid
                        columns={[
                            { propertyName: "mesAno", title: "Mês/Ano", columnAlignment: "center" },
                            { propertyName: "aporteMensal", title: "Aporte/Reaporte", columnAlignment: "right", onFormat(value) { return CodeUtil.moneyFormat(value) } },
                            { propertyName: "acumuladoCaixa", title: "Acumulado Caixa", columnAlignment: "right", onFormat(value) { return CodeUtil.moneyFormat(value) } },
                            { propertyName: "investimentoTotal", title: "Em Aplicação", columnAlignment: "right", onFormat(value) { return CodeUtil.moneyFormat(value) } },
                        ]}
                        data={props.data ?? []}
                        keyColumn="mesAno"
                        multiSelect={false}
                        caption={{
                            title: `Plano de investimento - ${props.model.meses} mês(es)`,
                            resume: 'Resumo do plano de investimentos'
                        }}
                        overflowY="overflow-y-auto max-h-60"
                        readOnly={true}
                        search={false}
                    />
                </div>)}
        </React.Fragment>
    )
}