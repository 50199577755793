import { AppConfig } from "../env.config";
import { ClienteDocumentoModel } from "../models/ClienteDocumentoModel";
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${AppConfig.ApiURL}/cliente/documento`;

export type ClienteDocumentoServiceProps = {
    clienteId: number;
    chaveAutenticacao?: string,
    nomeArquivo?: string,
    dataCriacaoInicio?: string,
    dataCriacaoTermino?: string
} & ServiceProps;

export class ClienteDocumentoService extends BaseService {
    public static async get(props: ClienteDocumentoServiceProps): Promise<DefaultResponse<ClienteDocumentoModel>> {
        let _url = `${AppConfig.ApiURL}/cliente/${props.clienteId}/documento?limit=${props.limit}&page=${props.page}`;
        if (props.chaveAutenticacao) _url += `&chaveAutenticacao=${props.chaveAutenticacao}`;
        if (props.nomeArquivo) _url += `&nomeArquivo=${props.nomeArquivo}`;
        if (props.dataCriacaoInicio) _url += `&dataCriacaoInicio=${props.dataCriacaoInicio}`;
        if (props.dataCriacaoTermino) _url += `&dataCriacaoTermino=${props.dataCriacaoTermino}`;
        return await this.GET<ClienteDocumentoModel>(_url);
    }

    public static async getId(id: number): Promise<DefaultResponse<ClienteDocumentoModel>> {
        return await this.GET<ClienteDocumentoModel>(`${ENDPOINT}/${id}`);
    }

    public static async add(model: ClienteDocumentoModel): Promise<DefaultResponse<ClienteDocumentoModel>> {
        const _url = `${AppConfig.ApiURL}/cliente/${model.clienteId}/documento`;
        const formData = new FormData();
        formData.append("file", model.file!, model.nomeArquivo);

        return await this.POSTFORM<ClienteDocumentoModel>(_url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    }

    public static async delete(id: number): Promise<DefaultResponse<ClienteDocumentoModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}