import { FC } from "react";
import CTAPanel from "../../../../components/CTAPanel";
import { CTAPanelButtonType } from "../../../../components/CTAPanel/types";
import { ClienteModel } from "../../../../models/ClienteModel";
import { SearchCTAProps } from "../../../../types";

export type CustomerSearchCTAProps = {
  onResetPassword: () => void;
} & SearchCTAProps<ClienteModel>

export const CustomerCTA: FC<CustomerSearchCTAProps> = (props) => {
  const [model,] = props.modelState;

  return (
    <CTAPanel
      buttons={[
        {
          label: "Inserir",
          type: CTAPanelButtonType.CTA,
          action: () => props.onAdd?.call(this),
        },
        {
          label: "Editar",
          type: CTAPanelButtonType.FLAT,
          disabled: !model || (model.id ?? 0) === 0,
          action: () => props.onEdit?.call(this),
        },
        {
          label: "Consultar",
          type: CTAPanelButtonType.FLAT,
          disabled: !model || (model.id ?? 0) === 0,
          action: () => props.onView?.call(this),
        },
        {
          label: "Excluir",
          type: CTAPanelButtonType.DESTRUCTIVE,
          disabled: !model || (model.id ?? 0) === 0,
          action: () => props.onDelete?.call(this),
        },
        {
          label: "Resetar Senha",
          type: CTAPanelButtonType.FLAT,
          disabled: !model || (model.id ?? 0) === 0,
          action: () => props.onResetPassword?.call(this),
        }
      ]}
    />
  );
};
