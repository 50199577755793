import { FC, Fragment } from "react";
import CodeUtil from "../../util/CodeUtil";
import { GroupboxProps } from "./types";

export const Groupbox : FC<GroupboxProps> = (props) => {
    return (
        <Fragment>
            <div className={props.className}>
                <hr className={`${CodeUtil.isNullOrEmpty(props.label) ? '' : 'mb-6'}`} />
                {CodeUtil.isNullOrEmpty(props.label) ? (<></>) : (<h2 className="ml-auto text-xl font-extrabold text-black/70">{props.label}</h2>)}
                {props.children}
            </div>
        </Fragment>
    );
};