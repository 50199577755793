import { TipoDocumento, TipoUsuario } from "../enums";
import { FluentValidator } from "../types";
import CodeUtil from "../util/CodeUtil";
import { ContratoModel } from "./ContratoModel";
import { ClienteDocumentoModel } from "./ClienteDocumentoModel";
import { UsuarioModel } from "./UsuarioModel";

export interface ClienteModel extends UsuarioModel {
    tipoDocumento: TipoDocumento;
    documento: string;
    logradouro?: string;
    logradouroNumero?: string;
    complemento?: string;
    cep?: string;
    bairro?: string;
    usuarioId?: number,
    clienteGrupoId: number,
    grupo: {
        id: number;
        descricao: string;
    }

    municipioId?: number,
    municipio?: {
        id?: number;
        descricao?: string;
    };

    uf?: {
        id?: number;
        descricao?: string;
        sigla?: string;
    };
    bloqueado: boolean;
    contratos: ContratoModel[];
    anexos: ClienteDocumentoModel[];
}

export interface ClienteModelPost extends ClienteModel {
    municipioId?: number;
    clienteGrupoId: number;
}

export const defaultCliente: ClienteModel = {
    documento: "",
    email: "",
    clienteGrupoId: 1,
    grupo: {
        id: 1,
        descricao: "Padrão"
    },
    isBloqueado: true,
    isSelected: false,
    nome: "",
    nomeUsuario: "",
    tipoDocumento: TipoDocumento.cpf,
    tipoUsuario: TipoUsuario.CLIENTE,
    bloqueado: true,
    contratos: [],
    anexos: []
}

export const validateClienteModel = (model: ClienteModel): FluentValidator[] => {
    let response: FluentValidator[] = [];
    if (CodeUtil.isNullOrEmpty(model.nome)) {
        response.push({ field: "nome", isValid: false, message: "O nome deve ser preenchido." });
    }

    if (CodeUtil.isNullOrEmpty(model.documento)) {
        response.push({ field: "documento", isValid: false, message: "O documento (\"CPF\", \"CNPJ\" ou \"Passaporte\") deve ser preenchido." });
    }

    if (CodeUtil.isNullOrEmpty(model.email)) {
        response.push({ field: "email", isValid: false, message: "O campo e-mail deve ser preenchido." });
    }

    if (!CodeUtil.isNullOrEmpty(model.email) && !CodeUtil.isValidEmail(model.email)) {
        response.push({ field: "email", isValid: false, message: "O e-mail informado não é um e-mail válido." });
    }

    if (model.grupo.id <= 0) {
        response.push({ field: "grupo", isValid: false, message: "O grupo deve ser selecionado." });
    }

    if (CodeUtil.isNullOrEmpty(model.logradouroNumero) && !CodeUtil.isNullOrEmpty(model.cep)) {
        response.push({ field: "logradouroNumero", isValid: false, message: "O número deve ser informado." });
    }

    return response;
}