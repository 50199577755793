import { FC } from "react";
import { useAccountBalanceContext } from "../../../../../../contexts/AccountBallanceContext";
import CodeUtil from "../../../../../../util/CodeUtil";
import { CardExtratoItemProps } from "../../types";

export const CardExtratoItem: FC<CardExtratoItemProps> = (props) => {
    const { showAccountBalance: isShowingBalance } = useAccountBalanceContext();

    return (
        <div className="flex">
            <div className="mr-auto">
                <span className="text-xs">{props.movimento.descricao}</span>
            </div>
            <div className="ml-auto">
                {isShowingBalance ?
                    <span className={`text-xs mr-2 ${props.movimento.valor < 0 ? "text-red-600" : ""}`}>{CodeUtil.moneyFormat(props.movimento.valor)}</span> :
                    <div className='rounded bg-slate-200 h-5 w-24'></div>}
            </div>
        </div>
    );
};