import { AppConfig } from "../env.config";
import { ConsultorModel } from "../models/ConsultorModel";
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${AppConfig.ApiURL}/consultor`;

export class ConsultorService extends BaseService {
    public static async get(props: ServiceProps = { limit: 100, page: 1 }) : Promise<DefaultResponse<ConsultorModel>> {
        return await this.GET<ConsultorModel>(`${ENDPOINT}?limit=${props.limit}&page=${props.page}`);
    }

    public static async getId(id: number, props: ServiceProps = { limit: 100, page: 1 }) : Promise<DefaultResponse<ConsultorModel>> {
        return await this.GET<ConsultorModel>(`${ENDPOINT}/${id}?limit=${props.limit}&page=${props.page}`);
    }

    public static async add(model: ConsultorModel) : Promise<DefaultResponse<ConsultorModel>> {
        return await this.POST<ConsultorModel>(`${ENDPOINT}`, model);
    }

    public static async update(model: ConsultorModel) : Promise<DefaultResponse<ConsultorModel>> {
        return await this.PATCH<ConsultorModel>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async delete(id: number) : Promise<DefaultResponse<ConsultorModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}