import { TipoUsuario } from "../enums";
import { FluentValidator } from "../types";
import CodeUtil from "../util/CodeUtil";
import { BaseModel } from "./BaseModel";

export interface UsuarioModel extends BaseModel {
    nome: string;
    sobrenome?: string;
    nomeCompleto?: string;
    email: string;
    foto?: string;
    nomeUsuario: string;
    tipoUsuario: "ADMIN" | "CONSULTOR" | "CONTADOR" | "CLIENTE";
    token?: string;
    isBloqueado: boolean;
    clienteId?: number;
    consultorId?: number;
}

export const defaultUsuario: UsuarioModel = {
    nome: '',
    email: '',
    nomeUsuario: '',
    tipoUsuario: TipoUsuario.ADMIN,
    isSelected: false,
    isBloqueado: false
}

export const validateUsuario = (model: UsuarioModel): FluentValidator[] => {
    let result: FluentValidator[] = [];

    if (CodeUtil.isNullOrEmpty(model.nome?.trim()))
        result.push({ field: 'nome', message: 'O nome do usuário deve ser informado', isValid: false });

    if (CodeUtil.isNullOrEmpty(model.email?.trim()))
        result.push({ field: 'email', message: 'O e-mail do usuário deve ser informado.', isValid: false });
    else if (!CodeUtil.isValidEmail(model.email?.trim()))
        result.push({ field: 'email', message: 'O e-mail informado não é um e-mail válido.', isValid: false });

    if (CodeUtil.isNullOrEmpty(model.nomeUsuario?.trim()))
        result.push({ field: 'nomeUsuario', message: 'O login deve ser informado.', isValid: false });

    return result;
}