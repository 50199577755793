import { FC } from "react";
import CTAPanel from "../../../../components/CTAPanel";
import { CTAPanelButtonType } from "../../../../components/CTAPanel/types";
import { ConsultorModel } from "../../../../models/ConsultorModel";
import { SearchCTAProps } from "../../../../types";

export const DealerCTA : FC<SearchCTAProps<ConsultorModel | null>> = (props) => {
    const [model, ] = props.modelState;

    return (
      <CTAPanel
        buttons={[
          {
            label: "Inserir",
            type: CTAPanelButtonType.CTA,
            action: () => props.onAdd?.call(this),
          },
          {
            label: "Editar",
            type: CTAPanelButtonType.FLAT,
            disabled: model === undefined,
            action: () => props.onEdit?.call(this),
          },
          {
            label: "Consultar",
            type: CTAPanelButtonType.FLAT,
            disabled: model === undefined,
            action: () => props.onView?.call(this),
          },
          {
            label: "Excluir",
            type: CTAPanelButtonType.DESTRUCTIVE,
            disabled: model === undefined,
            action: () => props.onDelete?.call(this),
          },
        ]}
      />
    );
};

export default DealerCTA;