import { Button, Tooltip } from "flowbite-react";
import { FC, useState } from "react";
import { FormStateType } from "../../../enums";
import { ModalOrder } from "../../ModalOrder";

export const ToolbarCreateOrderButton: FC = () => {
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <div className="flex items-center">
            <Tooltip content="Criar ordem">
                <Button size="sm" gradientDuoTone="cyanToBlue" onClick={() => setShowModal(true)}>
                    <span className="hidden md:block">Criar</span>
                    <span className="block md:hidden">+</span>
                </Button>
            </Tooltip>

            <ModalOrder 
                show={showModal} 
                state={FormStateType.add}
                onClose={() => setShowModal(false) }/>
        </div>
    );
};