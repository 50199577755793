import { Pagination } from 'flowbite-react';
import React from 'react';
import { DataGridPaginationProps } from '../types';

const DataGridPagination : React.FC<DataGridPaginationProps> = ({columnCount, pageCount, pageIndex, onPageChange}) => {
    return (
        <tfoot>
            <tr className='bg-gray-50'>
                <td colSpan={columnCount} className="px-5 py-2">
                    <div className='flex justify-between items-center'>
                        <div className='text-lg font-semibold text-left text-gray-900'>
                            <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                Exibindo página <span className="mr-1 font-semibold text-gray-900 dark:text-white">{pageIndex}</span>
                                de <span className="font-semibold text-gray-900 dark:text-white">{pageCount}</span>
                            </span>
                        </div>
                
                        <div className='flex items-center justify-end -mt-2'>
                            <Pagination layout='pagination' 
                                currentPage={pageIndex} 
                                totalPages={pageCount} 
                                previousLabel=""
                                nextLabel=""
                                showIcons={true}
                                onPageChange={(page) => onPageChange?.call(this, page)} />
                        </div>
                    </div>
                </td>
            </tr>
        </tfoot>        
    )
}

export default DataGridPagination;