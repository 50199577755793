import { Alert } from "flowbite-react";
import React from "react";
import styled from "styled-components";
import CodeUtil from "../../util/CodeUtil";
import { ErrorBadgeProps } from "./types";

const ErrorBadge: React.FC<ErrorBadgeProps> = (props) => {
  return (
    <div>
      {(props.errors?.length ?? 0) === 0 ? (
        <></>
      ) : (
        <Alert color="failure">
          <StyledErrorBadge>
            {props.errors?.filter((value) => !CodeUtil.isNullOrEmpty(value)).map((error, idx) => (
                <div key={idx} className="form-control">
                  <li>{error}</li>
                </div>              
            ))}
          </StyledErrorBadge>
        </Alert>
      )}
    </div>
  );
};

const StyledErrorBadge = styled.ul`
    list-style-type: disc;
    padding: 0px 20px;
    justify-items: center;
    text-align: left;
    align-items: center;
`

export default ErrorBadge;
