import { FC } from "react";
import { ContratoTipo } from "../../../../models/ContratoModel";
import { CardAprovacoes } from "../CardAprovacoes";
import { CardExtrato } from "../CardExtrato";
import { ChartHistoricoMovimentacao } from "../ChartHistoricoMovimentacao";

export type MainDashboardProps = { tipoContrato: ContratoTipo }
export const MainDashboard: FC<MainDashboardProps> = (props) => {
    return (
        <div className="space-x-3 space-y-3 flex flex-wrap place-content-center lg:place-content-stretch">
            <div className="mt-3 ml-3"><CardExtrato tipoContrato={[props.tipoContrato]} /></div>
            <CardAprovacoes tipoContrato={[props.tipoContrato]} />
            <ChartHistoricoMovimentacao tipoContrato={[props.tipoContrato]} />
        </div>
    );
};