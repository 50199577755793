import { Tabs } from "flowbite-react";
import { FC, useCallback, useEffect, useState } from "react";
import { ShowToast } from "../../../../components/CodeToast";
import { ModalForm } from "../../../../components/ModalForm";
import { ModalFormProps } from "../../../../components/ModalForm/types";
import { FormStateType } from "../../../../enums";
import { ClienteModel, defaultCliente, validateClienteModel } from "../../../../models/ClienteModel";
import { ClienteService } from "../../../../services/ClienteService";
import { FluentValidator } from "../../../../types";
import CodeUtil from "../../../../util/CodeUtil";
import { UNKOWN_EXCEPTION } from "../../../../util/Constants";
import { CustomersTabAnexos } from "./TabAnexos";
import { CustomersTabContratos } from "./TabContratos";
import { CustomersTabDados } from "./TabDados";

export const CustomersModal: FC<ModalFormProps> = (props) => {
  const [errors, setErrors] = useState<FluentValidator[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [formLoaded, setFormLoaded] = useState<boolean>(false);
  const [model, setModel] = useState<ClienteModel>(defaultCliente);

  const onFormClose = () => {
    setErrors([]);
    setFormLoaded(false);
    props.onClose?.call(this);
  };

  const onFormLoad = useCallback(async () => {
    if (!props.show || formLoaded) return;

    if (props.id !== null && props.id !== undefined && props.id > 0) {
      let response = await ClienteService.getId(props.id);
      if (!response.success) {
        ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
        return;
      }

      setModel(response.data[0]);
    } else setModel(defaultCliente);

    setFormLoaded(true);
  }, [props.id, props.show, formLoaded, setFormLoaded]);

  const onFormSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
    let validationResult = validateClienteModel(model);
    setErrors(validationResult);


    if (validationResult.length > 0) {
      ShowToast({ message: "Um ou mais campos inválidos." });
      return;
    }

    try {
      setSaving(true);
      console.log(model);

      let response = props.state === FormStateType.add ? await ClienteService.add(model) : await ClienteService.update(model);
      if (!response.success) {
        ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
        return;
      }

      props.onSave?.call(this, e);
      onFormClose();
    } catch (error) {
      console.log(error);
      ShowToast({ message: UNKOWN_EXCEPTION });
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    onFormLoad();
  });

  if (!props.show) return <></>;

  return (
    <ModalForm
      title={props.title ?? "Cadastro de Clientes"}
      show={props.show}
      state={props.state}
      isSaving={saving}
      size="4xl"
      onClose={onFormClose}
      onSave={onFormSave}
    >
      {/* eslint-disable-next-line */}
      <Tabs.Group aria-label="Abas" style="underline">
        <Tabs.Item title="Dados do Cliente" active={true} key="customer">
          <CustomersTabDados {...props}
            model={[model, setModel]}
            errors={[errors, setErrors]}
          />
        </Tabs.Item>

        <Tabs.Item title="Contratos" key="contract">
          <CustomersTabContratos {...props}
            model={[model, setModel]}
            errors={[errors, setErrors]}
          />
        </Tabs.Item>

        {props.state === FormStateType.add ? <></> :
          <Tabs.Item title="Anexos" key="anexos">
            <CustomersTabAnexos {...props}
              model={[model, setModel]}
              errors={[errors, setErrors]}
            />
          </Tabs.Item>}
      </Tabs.Group>
    </ModalForm>
  );
};

export default CustomersModal;