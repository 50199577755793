import { FC } from "react";
import styled from "styled-components";
import { CodeTabbedTitleDividerProps } from "../../types";

export const CodeTabbedTitleDivider: FC<CodeTabbedTitleDividerProps> = (props) => {
    return (<StyledDivider onClick={() => props.onClick?.call(this, props.itemIndex)} aria-selected={props.active ? "true" : "false"} />);
};

const StyledDivider = styled.div`
    padding: 0.35rem 0rem;
    width: 1.25rem;
    border-bottom: 0.125rem solid ${props => props["aria-selected"] === "true" ? "#3cbfae" : "rgb(209, 213, 219)"};
    cursor: pointer;
    transition: border-bottom .3s ease-in;    
`;