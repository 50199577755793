import { FluentValidator } from "../types";

export interface CalculadoraInvestimentoModel {
    aporteInicial: number | null, 
    aporteMensal: number | null,
    meses: number,
    taxaJuros: number
}

export const defaultCalculadoraInvestimentosModel : CalculadoraInvestimentoModel = {
    aporteInicial: null,
    aporteMensal: null,
    meses: 120,
    taxaJuros: 1.75
}

export const validateModel = (model: CalculadoraInvestimentoModel) : FluentValidator[] => {
    let result: FluentValidator[] = []; 

    if ((model.aporteInicial ?? 0) < 0)
        result.push({ field: 'aporteInicial', message: 'O valor do aporte inicial deve ser maior ou igual a zero.', isValid: false });

    if (model.aporteMensal === null ||model.aporteMensal < 0)
        result.push({ field: 'aporteMensal', message: 'O valor do aporte mensal deve ser maior ou igual a zero.', isValid: false });

    if (model.taxaJuros === null || model.taxaJuros <= 0)
        result.push({ field: 'taxaJuros', message: 'A taxa de juros deve ser maior que zero.', isValid: false });

    if (model.meses === undefined || model.meses <= 0)
        result.push({ field: 'meses', message: 'A quantidade de meses deve ser maior que zero.', isValid: false });

    return result;
}