import React from 'react'
import { Navigate } from 'react-router-dom';
import { useSessionContext } from '../contexts/SessionContext';
import { ROUTE_LOGIN } from '../util/AppRoutes';

export default function useRouteGuard(children?: JSX.Element, redirectPath?: string, can?: boolean): JSX.Element {
    const sessionContext = useSessionContext();
    const isSigned: boolean = (sessionContext.session?.signed &&
        Boolean(sessionContext.session.usuario?.token)) ?? false;

    let toPath = isSigned ? (redirectPath ?? '/') : ROUTE_LOGIN;

    if (isSigned && (can ?? true))
        toPath = "/home";

    return ((isSigned && (can ?? true)) ? (children ?? <></>) : <Navigate to={toPath} />)
}