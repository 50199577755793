import { TipoAutenticacao } from "../enums";
import CodeUtil from "../util/CodeUtil";
import { SessionManager } from "../util/SessionManager";

export const validateVM = (vm: AuthViewModel) : string[] => {

    let errors: string[] = [];

    if (vm.login?.trim() === null || vm.login?.trim() === '') {
        errors.push("O login deve ser preenchido.");
    } else if (vm.tipoAutenticacao === TipoAutenticacao.EMAIL && !CodeUtil.isValidEmail(vm.login?.trim())) {
        errors.push("O e-mail informado não é um e-mail válido.");
    }

    if (vm.senha?.trim() === null || vm.senha?.trim() === '') {
        errors.push("A senha deve ser preenchida.");
    }

    return errors;
}

const currentSession = SessionManager.getSession();

export class AuthViewModel {
    login: string = currentSession.rememberMe ? (currentSession.usuario?.email ?? '') : '';
    senha: string = '';
    tipoAutenticacao: TipoAutenticacao = TipoAutenticacao.EMAIL;
    rememberMe: boolean = currentSession.rememberMe;
}

export function AsDefaultAuthViewModel() : AuthViewModel {
    return {
        login: currentSession.rememberMe ? (currentSession.usuario?.email ?? '') : '',
        senha: '',
        tipoAutenticacao: TipoAutenticacao.EMAIL,
        rememberMe: currentSession.rememberMe
    };
}