import { AppConfig } from "../env.config";
import { EstadoModel } from '../models/EstadoModel';
import { BaseService } from "./BaseService";
import { DefaultRequest } from "./requests";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${AppConfig.ApiURL}/estado`;

export class EstadoService extends BaseService {
    public static async get(props: DefaultRequest = { limit: 100, page: 1 }) : Promise<DefaultResponse<EstadoModel>> {
        return await this.GET<EstadoModel>(`${ENDPOINT}?limit=${props.limit}&page=${props.page}`);
    }

    public static async getId(id: number, props: ServiceProps = { limit: 100, page: 1 }) : Promise<DefaultResponse<EstadoModel>> {
        return await this.GET<EstadoModel>(`${ENDPOINT}/${id}?limit=${props.limit}&page=${props.page}`);
    }
}