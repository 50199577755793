import * as flowbiteReact from "flowbite-react";
import { AsDefaultAuthViewModel, AuthViewModel } from "../../../viewmodels/AuthViewModel";
import LoginPageCta from "./components/LoginPageCTA";
import Logo from '../../../images/logo.png';
import React, { useState } from "react";
import SidePanel from "../../../components/SidePanel";
import ErrorBadge from "../../../components/ErrorBadge";
import '../../../index.css';
import CodeUtil from "../../../util/CodeUtil";
import { TipoAutenticacao } from "../../../enums";

export type InputLoginProps = {
  type: string,
  placeHolder: string,
  label: string
}

const LoginPage: React.FC = () => {
  
  const [inputLoginProps, setInputLoginProps] =  useState<InputLoginProps>({label: "E-mail:", placeHolder: "email@email.com.br", type: "email" });
  const [authModel, setAuthModel] = useState<AuthViewModel>(AsDefaultAuthViewModel());
  const [errors, setErrors] = useState<string[]>([]);

  const onSelectTipoAutenticacao: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    e.preventDefault();

    let _tipoAutenticacao = CodeUtil.stringToEnum<TipoAutenticacao>(e.currentTarget.value);
    setAuthModel({...authModel, tipoAutenticacao: _tipoAutenticacao, login: '', senha: '' });

    switch (_tipoAutenticacao) {
      case TipoAutenticacao.EMAIL:
      default:
        setInputLoginProps(prevProps => ({...prevProps, label: "E-mail:", placeHolder: "email@email.com.br", type: "email" }));
        break;  
      case TipoAutenticacao.DOCUMENTO:
        setInputLoginProps(prevProps => ({...prevProps, label: "Documento (somente números):", placeHolder: " CPF, CNPJ ou Passaporte", type: "text" }));
        break;
      case TipoAutenticacao.NOME_USUARIO:
        setInputLoginProps(prevProps => ({...prevProps, label: "Nome de usuário:", placeHolder: "usuario.nome", type: "text" }));
        break;
    }
  }    

  const onInputLoginChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    
    if (authModel.tipoAutenticacao !== TipoAutenticacao.DOCUMENTO) {
      setAuthModel({...authModel, login: e.currentTarget.value});
      return; 
    }
      
    if (e.currentTarget.validity.valid) {
      setAuthModel({...authModel, login: e.currentTarget.value});
    }
  }

  const handlerValidate = (errors: string[]) => setErrors(errors);

  return (
    <div className="login">
        <SidePanel> 
          <form method="POST">
            <div className="mt-5">
              <div className="flex justify-center">
                <img src={Logo} className="h-11 mb-3" alt="Atac Logo"/>
              </div>
            </div>

            <div className="mt-5 mb-5">
              <ErrorBadge errors={errors} />
            </div>

            <div className="form-control">
              <div className="mb-1"><flowbiteReact.Label htmlFor="selectTipo" value="Tipo de autenticação:"/></div>
              <flowbiteReact.Select id="selectTipo" defaultValue={authModel.tipoAutenticacao} onChange={onSelectTipoAutenticacao}>
                <option value={TipoAutenticacao.EMAIL}>E-mail</option>
                <option value={TipoAutenticacao.DOCUMENTO}>Documento (CPF, CNPJ ou Passaporte)</option>
                <option value={TipoAutenticacao.NOME_USUARIO}>Nome de usuário</option>
              </flowbiteReact.Select>
            </div>

            <div className="form-control mt-5">
              <div className="mb-1"><flowbiteReact.Label htmlFor="inputLogin" value={inputLoginProps.label}/></div>
              <flowbiteReact.TextInput id="inputLogin"
                value={authModel.login}
                pattern="[0-9]*"
                maxLength={authModel.tipoAutenticacao === TipoAutenticacao.DOCUMENTO ? 14 : 256}
                onChange={ onInputLoginChange } 
                type={inputLoginProps.type} 
                placeholder={inputLoginProps.placeHolder} />
            </div>

            <div className="form-control mt-5">
              <div className="mb-1"><flowbiteReact.Label htmlFor="inputSenha" value="Senha:"/></div>
              <flowbiteReact.TextInput id="inputSenha"
                type="password" 
                value={authModel.senha}
                placeholder="Digite sua senha"
                onChange={(e) => setAuthModel({...authModel, senha: e.currentTarget.value}) } />
            </div>

            <div className="mt-6">
              <div className="flex justify-start gap-2">
                  <flowbiteReact.Checkbox checked={authModel.rememberMe} id="remember"
                    onChange={(e) => setAuthModel({...authModel, rememberMe: Boolean(e.currentTarget.value) }) } />
                  <flowbiteReact.Label htmlFor="remember">Lembrar meu login</flowbiteReact.Label>
                </div>
            </div>

            <LoginPageCta vm={authModel} onValidate={handlerValidate} />
          </form>
        </SidePanel>        
    </div>
  );
};

export default LoginPage;
