import { FC, MouseEvent } from "react";
import styled from "styled-components";
import { SideBarItem } from "./Item";
import { SideBarListItems } from "./ListItems";
import { RiApps2Fill } from 'react-icons/ri'
import { SidebarHeader } from "./Header";
import { SidebarProps } from "./types";
import { useSessionContext } from "../../contexts/SessionContext";
import { SidebarAdminItems } from "./SidebarAdminItems";
import { ROUTE_HOME, ROUTE_LOGIN } from "../../util/AppRoutes";
import { SessionManager } from "../../util/SessionManager";
import OAuthService from "../../services/OAuthService";
import { useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import useUserRole from "../../hooks/UserRoleHook";
import { SidebarCustomerItems } from "./SidebarCustomerItems";

export const Sidebar: FC<SidebarProps> = (props) => {
    const sessionContext = useSessionContext();
    const { refreshSession } = useSessionContext();
    const navigate = useNavigate();
    const currentRole = useUserRole();

    const onSidebarItemClick = () => {
        SessionManager.setSession({ ...SessionManager.getSession(), hiddenSidebar: true });
        sessionContext.refreshSession();

        props.onItemClick?.call(this);
    }

    const handleSidebarOutsideClick = (e: MouseEvent<HTMLDivElement>) => {
        let _session = SessionManager.getSession();
        SessionManager.setSession({ ..._session, hiddenSidebar: true });
        refreshSession();
    }

    const handlerLogout = () => {

        OAuthService.logout();
        sessionContext.refreshSession();

        navigate(ROUTE_LOGIN);
    }

    if (props.hidden) return <></>;

    console.log(currentRole);

    return (
        <StyledSidebar>
            <div id="sidebar-container" className="flex">
                <div className="sidebar overflow-y-auto py-4 px-3 shadow-sm">
                    <SidebarHeader />

                    <SideBarListItems>
                        <SideBarItem onClick={onSidebarItemClick} to={ROUTE_HOME} label="Home" icon={<RiApps2Fill className="w-5 h-5 transition duration-75 text-gray-500" />} />
                        {currentRole !== "CLIENTE" ? <SidebarAdminItems {...props} /> : <></>}
                        {currentRole === "CLIENTE" ? <SidebarCustomerItems {...props} /> : <></>}
                        <hr />
                        <SideBarItem onClick={handlerLogout} textColor="text-red-600"
                            icon={<MdLogout className="w-5 h-5 transition duration-75 text-red-600" />}
                            label="Sair do Sistema" />
                    </SideBarListItems>
                </div>

                <div className="sidebar-box" onClickCapture={handleSidebarOutsideClick} />
            </div>
        </StyledSidebar>
    )
};


const StyledSidebar = styled.aside`
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 50;

    #sidebar-container > .sidebar-box {
        background-color: rgba(0, 0, 0, 0.35);
        min-height: 100vh;
        margin-left: 60;
        width: calc(100% - 60px);
    }

    #sidebar-container > .sidebar {
        background-color: rgb(255, 255, 255);
        min-width: 16rem;
        min-height: 100vh;
        width: 60px;
    }
`;