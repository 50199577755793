import { AppConfig } from "../env.config";
import { BaseService } from "./BaseService";
import { CalculadoraInvestimentoModel } from "../models/CalculadoraInvestimentoModel";
import { CalculadoraInvestimentosViewModel } from "../viewmodels/CalculadoraInvestimentosViewModel";
import { DefaultResponse } from "./types";

const ENDPOINT = `${AppConfig.ApiURL}/calculadora`;

export class CalculadoraInvestimentoService extends BaseService {
    public static async calcular(model: CalculadoraInvestimentoModel) : Promise<DefaultResponse<CalculadoraInvestimentosViewModel>> {
        return await this.POST<CalculadoraInvestimentoModel>(`${ENDPOINT}`, model);
    }
}