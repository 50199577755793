import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { SessionManager } from "../util/SessionManager";
import OAuthService from "./OAuthService";
import { AsDefaultResponse, DefaultResponse } from "./types";

export class BaseService {
    protected static updateBearerToken = (_axios: AxiosInstance) => {
        _axios.defaults.headers.Authorization = `Bearer ${OAuthService.getSessionUser()?.token}`;
    }

    protected static redirectToAuth = (statusCode: number | undefined) => {
        if (statusCode === 401) {
            SessionManager.clearSession();
            window.location.href = "/auth/login";
        }
    }

    protected static async GET<T>(endpoint: string): Promise<DefaultResponse<T>> {
        try {

            this.updateBearerToken(axios);
            return (await axios.get(endpoint)).data as DefaultResponse<T>;
        } catch (error: any) {
            let axiosError = (error as AxiosError);

            this.redirectToAuth(axiosError.response?.status);

            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                return AsDefaultResponse((error as AxiosError).message, []);
            }

            return axiosError.response!.data as DefaultResponse<T>;
        }
    }

    protected static async POST<T>(endpoint: string, data: T): Promise<DefaultResponse<T>> {
        try {

            this.updateBearerToken(axios);
            return (await axios.post(endpoint, data)).data as DefaultResponse<T>;
        } catch (error: any) {
            let axiosError = (error as AxiosError);

            this.redirectToAuth(axiosError.response?.status);

            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                return AsDefaultResponse((error as AxiosError).message, []);
            }

            return axiosError.response!.data as DefaultResponse<T>;
        }
    }

    protected static async POSTFORM<T>(endpoint: string, data: FormData, config?: AxiosRequestConfig<FormData>): Promise<DefaultResponse<T>> {
        try {

            this.updateBearerToken(axios);
            return (await axios.postForm(endpoint, data, config)).data as DefaultResponse<T>;
        } catch (error: any) {
            let axiosError = (error as AxiosError);

            this.redirectToAuth(axiosError.response?.status);

            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                return AsDefaultResponse((error as AxiosError).message, []);
            }

            return axiosError.response!.data as DefaultResponse<T>;
        }
    }

    protected static async PATCH<T>(endpoint: string, data: T): Promise<DefaultResponse<T>> {
        try {

            this.updateBearerToken(axios);
            return (await axios.patch(endpoint, data)).data as DefaultResponse<T>;
        } catch (error: any) {
            let axiosError = (error as AxiosError);

            this.redirectToAuth(axiosError.response?.status);

            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                return AsDefaultResponse((error as AxiosError).message, []);
            }

            return axiosError.response!.data as DefaultResponse<T>;
        }
    }

    protected static async PATCHFORM<T>(endpoint: string, data: FormData, config?: AxiosRequestConfig<FormData>): Promise<DefaultResponse<T>> {
        try {

            this.updateBearerToken(axios);
            return (await axios.patchForm(endpoint, data, config)).data as DefaultResponse<T>;
        } catch (error: any) {
            let axiosError = (error as AxiosError);

            this.redirectToAuth(axiosError.response?.status);

            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                return AsDefaultResponse((error as AxiosError).message, []);
            }

            return axiosError.response!.data as DefaultResponse<T>;
        }
    }

    protected static async DELETE(endpoint: string): Promise<DefaultResponse> {
        try {

            this.updateBearerToken(axios);

            return (await axios.delete(endpoint)).data as DefaultResponse;
        } catch (error: any) {
            let axiosError = (error as AxiosError);

            this.redirectToAuth(axiosError.response?.status);

            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                return AsDefaultResponse((error as AxiosError).message, []);
            }

            return axiosError.response!.data as DefaultResponse;
        }
    }
}