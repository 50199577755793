import { Badge } from 'flowbite-react';
import React, { useCallback, useEffect, useState } from 'react';
import DataGrid from '../../components/DataGrid';
import { HiCheck } from 'react-icons/hi/index'
import { IoMdClose } from 'react-icons/io/index'
import { UsuarioService } from '../../services/UsuarioService';
import { UsuarioModel } from '../../models/UsuarioModel';
import ProfileImage from '../../util/ProfileImage';
import { UserCTA } from './components/CTA';
import { FormStateType } from '../../enums';
import { ModalProps } from '../../types';
import { ShowToast } from '../../components/CodeToast';
import CodeSpinner from '../../components/CodeSpinner';
import { Popup } from '../../components/Popup';
import UserModal from './components/Modal';

const UsersPage: React.FC = () => {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [dataSource, setDataSource] = useState<UsuarioModel[]>([]);

  const [selectedModel, setSelectedModel] = useState<UsuarioModel | null>(null);
  const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalProps>({ show: false, state: FormStateType.view, id: 0 });

  const onFormLoad = useCallback(async () => {
    setIsLoadingData(true);
    UsuarioService.get({ page: pageIndex, limit: 100 }).then((response) => {
      setDataSource(response.data ?? []);
      setPageIndex(response.pagination.page);
      setPageCount(response.pagination.pageCount);
    }).finally(() => setIsLoadingData(false));
  }, [setDataSource, setIsLoadingData, pageIndex, setPageIndex, setPageCount]);

  useEffect(() => {
    onFormLoad()
  }, [onFormLoad]);

  const add = () => setModalState({ ...modalState, state: FormStateType.add, show: true });

  const viewOrEdit = (edit: boolean) => {
    if (selectedModel?.id === undefined) {
      ShowToast({ message: `Nenhum registro selecionado para ${edit ? 'edição' : 'visualização'}` });
      return;
    }

    setModalState({ state: edit ? FormStateType.edit : FormStateType.view, show: true, id: selectedModel.id });
  }

  const deleteItem = async () => {
    try {
      let response = await UsuarioService.delete(selectedModel!.id!);
      if (!response.success) {
        console.log(response.messages);
        return;
      }

      await onFormLoad();
    } catch (error) {
      console.log(error);
    }
    finally {
      setShowPopupDelete(false);
    }
  }

  const actionButtons = (): JSX.Element => {
    return (
      <UserCTA
        modelState={[selectedModel, setSelectedModel]}
        onAdd={() => add()}
        onEdit={async () => viewOrEdit(true)}
        onView={async () => viewOrEdit(false)}
        onDelete={() => setShowPopupDelete(true)} />
    );
  };

  return (
    <div>
      <CodeSpinner hidden={!isLoadingData} fullPage={true} />

      <div className='mt-5' hidden={isLoadingData}>

        <DataGrid
          multiSelect={false}
          search={true}
          sortedColumn='nome'
          keyColumn='id'
          columns={[
            { propertyName: 'id', title: '#' },
            {
              propertyName: 'isBloqueado', title: 'Ativo', columnAlignment: 'center',
              onFormat(value) {
                return (
                  <div className='flex justify-center flex-wrap items-center gap-2'>
                    {(value as boolean) ? <Badge size='sm' color='failure' icon={IoMdClose} /> : <Badge icon={HiCheck} size="sm" color='success' />}
                  </div>
                )
              },
            },
            {
              propertyName: 'nomeCompleto', title: 'Nome', onFormat(value, model) {
                return (<div className='flex'>
                  <img className="w-6 h-6 rounded-full" src={model.foto ?? ProfileImage} alt="" />
                  <div className='pl-2 pt-0.5'>{value}</div>
                </div>)
              }
            },
            { propertyName: 'email', title: 'E-mail' },
            { propertyName: 'nomeUsuario', title: 'Login' },
          ]}
          data={dataSource}
          caption={{
            title: 'Usuários',
            resume: 'Listagem de usuários ativos e inativos',
            children: actionButtons()
          }}
          pagination={{
            pageCount: pageCount,
            pageIndex: pageIndex,
            limit: 100,
            onPageChange: (pageIndex) => setPageIndex(pageIndex)
          }}
          onRowSelect={(selectedRow) => setSelectedModel(selectedRow[0] as UsuarioModel)}
        />
      </div>

      <UserModal
        show={modalState.show}
        id={modalState.id}
        state={modalState.state}
        title="Cadastro de Usuários"
        onClose={() => setModalState({ ...modalState, show: false })}
        onSave={async () => {
          setModalState({ ...modalState, show: false });
          await onFormLoad();
        }}
      />

      <Popup 
        isShowing={showPopupDelete} 
        message="Confirma a exclusão do registro selecionado?"
        isDestructive={true} 
        onConfirm={async () => await deleteItem()}
        onCancel={() => setShowPopupDelete(false)} />
    </div>
  )
}

export default UsersPage;