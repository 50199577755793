import { FC } from "react";
import styled from "styled-components";
import { SidePanelProps } from "./types";

const SidePanel : FC<SidePanelProps> = (props) => {
    return (
        <StyledSidePanel {...props}>
            {props.children}
        </StyledSidePanel>
    )
}

const StyledSidePanel = styled.div`
    background: rgba(255,255,255,0.82);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
    align-self: flex-end;
    overflow-x: hidden;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 1;
    min-height: 100%;
    padding: 2.4%;
    border-radius: 25px 0 0 0;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);    

    @media (max-width: 600px) {
        border-radius: 15px;
        top: 0;
        right: auto;
        min-height: 60vh;
        padding: 25px 0px;
        align-items: center;
        margin-top: 15vh;
        margin-left: 4%;        
        margin-right: 4%;
        width: 92%;
    }
`

export default SidePanel