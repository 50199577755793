import { AuthViewModel, validateVM } from "../../../../viewmodels/AuthViewModel";
import { Button, Spinner } from "flowbite-react";
import OAuthService from "../../../../services/OAuthService";
import React, { useState } from "react";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { useNavigate } from "react-router-dom";

type LoginCTAProps = {
    vm: AuthViewModel;
    onLogin?: React.MouseEventHandler<HTMLButtonElement> | null;
    onForgotPassword?: React.MouseEventHandler<HTMLButtonElement> | null;  
    onValidate?: (errors: string[]) => void;
}

const LoginPageCTA : React.FC<LoginCTAProps> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const sessionContext = useSessionContext();
    const navigate = useNavigate();
    
    const handlerEnviarLembreteSenha: React.MouseEventHandler<HTMLButtonElement> = (e) => { }

    const handlerAutenticar: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);

            let validationErrors = validateVM(props.vm);
            if (validationErrors.length > 0) {
                if (props.onValidate !== null && props.onValidate !== undefined)
                    props.onValidate(validationErrors);

                if (props.onLogin !== null && props.onLogin !== undefined)
                    props.onLogin(e);
                return;
            }
      
            var response = await OAuthService.auth(props.vm);
            if (!response.success) {
                if (props.onValidate !== null && props.onValidate !== undefined)
                    props.onValidate(response.messages);
                console.log(response.messages);
                return;
            }     

            sessionContext.refreshSession();
            navigate("/home");
        } catch(ex: any) {
            console.log(ex);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="mt-5 flex justify-end">
            <div className="mr-2"><Button size="md" color="light" onClick={handlerEnviarLembreteSenha}>Esqueci minha senha</Button></div>
            <Button size="md" gradientDuoTone="greenToBlue" onClick={handlerAutenticar} type="submit">
                <div className={isLoading ? "mr-3" : "" }>
                    <Spinner size="sm" light={true} hidden={!isLoading} />
                </div>  
                Entrar
            </Button>
        </div>
    )

}

export default LoginPageCTA;