import React from "react";
import ToolbarAvatar from "./Avatar";
import ToolbarMenu from "./Menu";
import ToolbarAccountBalance from "./AccountBalance";
import { useSessionContext } from "../../contexts/SessionContext";
import { SessionManager } from "../../util/SessionManager";
import { ToolbarBrand } from "./Brand";
import ToolbarToogle from "./Toogle";
import styled from "styled-components";
import { ToolbarCreateOrderButton } from "./CreateOrderButton";

const Toolbar: React.FC = () => {
  const { refreshSession } = useSessionContext();
  const toogleSidebar = () => {
    let _session = SessionManager.getSession();
    SessionManager.setSession({
      ..._session,
      hiddenSidebar: !(_session.hiddenSidebar ?? true),
    });
    refreshSession();
  };

  return (
    <header>
      <StyledToolbar className="px-4 py-4 lg:py-3 fixed min-w-full shadow-sm">
        <div className="flex flex-wrap">
          <ToolbarToogle onToogle={() => toogleSidebar()} />
          <ToolbarBrand />

          <div className="p-2 bg-white flex lg:ml-5 mr-auto">
            <ToolbarMenu />
          </div>

          <div className="flex md:order-2 ml-auto space-x-8">
            <ToolbarCreateOrderButton />
            {/* <ToolbarAlert /> */}
            <ToolbarAccountBalance />
            <ToolbarAvatar />
          </div>
        </div>
      </StyledToolbar>
    </header>
  );
};

const StyledToolbar = styled.nav`
    background-color: white;
    min-width: 100%;
    z-index: 49;
    transition: all 1ms;

    @media screen {
      
    }
`

export default Toolbar;
