import { AxiosError } from "axios";
import { Label, Select, TextInput } from "flowbite-react";
import { FC, useCallback, useEffect, useState } from "react";
import { ShowToast } from "../../../../components/CodeToast";
import { ModalForm } from "../../../../components/ModalForm";
import { ModalFormProps } from "../../../../components/ModalForm/types";
import { FormStateType } from "../../../../enums";
import { ClienteGrupoModel, defaultClienteGrupo, validateClienteGrupoModel } from "../../../../models/ClienteGrupoModel";
import { ClienteGrupoService } from "../../../../services/ClienteGrupoService";
import CodeUtil from "../../../../util/CodeUtil";

export const CustomersGroupsModal : FC<ModalFormProps> = (props) => {
    const [errors, setErrors] = useState<string[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [model, setModel] = useState<ClienteGrupoModel>(defaultClienteGrupo);
    const isReadOnly = props.state === FormStateType.view;

    const onFormClose = () => {        
        setErrors([]);
        setFormLoaded(false);
        props.onClose?.call(this);
    }
    
    const onFormLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        if (props.id !== undefined && props.id > 0) {
            let response = await ClienteGrupoService.getId(props.id);
            if (!response.success) {
                ShowToast({message: CodeUtil.arrayToStr(response.messages) });
                return;
            }

            setModel(response.data[0]);
        } else {
            setModel(defaultClienteGrupo);
        }

        setFormLoaded(true);        
    }, [props.id, props.show, formLoaded, setFormLoaded]);

    const onFormSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
        
        let validationResult = validateClienteGrupoModel(model);
        setErrors(validationResult.map((item) => item.message));

        if (validationResult.length > 0) return;        

        try{
            setSaving(true);
            let response = props.state === FormStateType.add ? await ClienteGrupoService.add(model) : 
                await ClienteGrupoService.update(model); 
            
            if (!response.success) {
                setErrors(response.messages);
                return;
            }
            
            props.onSave?.call(this, e);            
            onFormClose();
        } catch (error) {
            setErrors([(error as AxiosError).response?.data as string])
        }
        finally {
            setSaving(false);
        }
    }
    
    useEffect(() => {
        onFormLoad();
    });

    if (!props.show) return <></>;

    return (
        <ModalForm title={props.title}
            errors={errors} 
            show={props.show}
            state={props.state}
            onClose={onFormClose}
            isSaving={saving}
            size="2xl"
            onSave={async (e) => await onFormSave(e)}>

            <div className="grid grid-cols-12 space-y-5 p-4">
                <div className="col-span-12">
                    <h2 className="ml-auto text-xl font-extrabold text-black/70" hidden={model.id === 0 || model.id === undefined || model.id === null}>
                    {`ID: #${(model.id ?? 0).toString().padStart(3, "0")}`}
                    </h2>
                </div>

                <div className="form-control col-span-12">
                    <div className="mb-1"><Label htmlFor="inputNome" value="Grupo:"/></div>
                    <TextInput id="inputNome"
                        value={model.descricao}
                        maxLength={60}
                        readOnly={isReadOnly}
                        required={true}
                        onChange={ (event) => setModel({...model, descricao: event.currentTarget.value })} 
                        type="text"
                        placeholder="Nome do grupo" />
                </div>

                <div className="form-control col-span-12 md:col-span-4 md:mr-6">
                    <div className="mb-1"><Label htmlFor="inputPercentualAutoRendimento" value="Taxa de Juros:"/></div>
                    <TextInput id="inputPercentualAutoRendimento"
                        value={model.percentualAutoRendimento?.toFixed(2) ?? 0.00}
                        maxLength={60}   
                        addon="%"                                             
                        onChange={ (event) => setModel({...model, percentualAutoRendimento: Number.parseFloat(event.currentTarget.value ?? 0) })} 
                        type="number"           
                        step={0.01}        
                        readOnly={isReadOnly}     
                        placeholder="0,00" />
                </div>

                <div className="form-control col-span-12 md:col-span-4">
                    <div className="mb-1"><Label htmlFor="inputSituacao" value="Situação:"/></div>
                    <Select id="dropDownSituacao" disabled={isReadOnly}
                        value={model.ativo ?? false ? 0 : 1}
                        onChange={ (event) => setModel({...model, ativo: Number(event.currentTarget.value) === 0 })}>
                            <option value={0}>Ativo</option>
                            <option value={1}>Inativo</option>
                    </Select>
                </div>
            </div>
        </ModalForm>
    )
}

export default CustomersGroupsModal