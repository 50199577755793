import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useSessionContext } from "../../../../../../contexts/SessionContext";
import useUserRole from "../../../../../../hooks/UserRoleHook";
import { OrdemModel } from "../../../../../../models/OrdemModel";
import PngMindfullness from '../../../../../../images/mindfulness.png';
import { CardAprovacoesTimelineProps } from "../../types";
import { OrdemService, OrdemServiceProps } from "../../../../../../services/OrdemService";
import { ShowToast } from "../../../../../../components/CodeToast";
import { OrdemStatus } from "../../../../../../enums";
import CodeUtil from "../../../../../../util/CodeUtil";
import { UNKOWN_EXCEPTION } from "../../../../../../util/Constants";
import { Checkbox, Timeline } from "flowbite-react";
import { CardAprovacoesItem } from "../CardAprovacoesItem";

export const CardAprovacoesTimeline: FC<CardAprovacoesTimelineProps> = (props) => {
    const [ordens, setOrdens] = props.data;
    const [timeline, setTimeline] = useState<string[]>([]);
    const [checkedAll, setCheckedAll] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = props.dataLoaded;

    const currentSession = useSessionContext();
    const currentUserRole = useUserRole();

    const handleCheckedAll = (e: ChangeEvent<HTMLInputElement>) => {
        setCheckedAll(e.currentTarget.checked);
        setOrdens(ordens.map(ordem => {
            ordem.isSelected = e.currentTarget.checked;
            return ordem;
        }));
    }

    const buscarOrdensPendentes = useCallback(async () => {
        try {
            if (dataLoaded) return;

            setDataLoaded(true);
            setOrdens([]);
            setTimeline([]);

            let params: OrdemServiceProps = {
                limit: 0,
                page: 1,
                completo: true,
                status: [OrdemStatus.Pendente],
                tipoContrato: props.tipoContrato,
                clienteId: currentUserRole === "CLIENTE" ? currentSession.session?.usuario?.clienteId : undefined
            };

            let response = await OrdemService.get(params);
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) })
                return;
            }

            let data = response.data as OrdemModel[];
            setOrdens(response.data);

            let _timeline = data.map(item => new Date(item.data).toLocaleDateString());
            setTimeline(Array.from(new Set<string>(_timeline)));
        } catch (error) {
            ShowToast({ message: UNKOWN_EXCEPTION })
            console.log(error);
        }
    }, [props.tipoContrato, currentSession, currentUserRole, dataLoaded, setDataLoaded, setOrdens]);

    useEffect(() => {
        buscarOrdensPendentes();
    }, [buscarOrdensPendentes])

    if (ordens.length === 0) {
        return (
            <div className="p-6 flex-col content-center justify-center text-center">
                <img alt="mindfulness" src={PngMindfullness} width={100} height={100} className="w-48 h-48 mb-3" />
                <span className="text-sm font-normal text-slate-700">Sem aprovações pendentes.<br />Aproveite para relaxar!</span>
            </div>
        );
    };

    return (
        <div>
            <div className="flex">
                <div className="ml-auto">
                    {props.readonly ? <></> : <Checkbox checked={checkedAll} onChange={(handleCheckedAll)} />}
                </div>
            </div>

            <div className="my-0">
                <Timeline>
                    {timeline.map(date =>
                        <Timeline.Item key={date}>
                            <Timeline.Point />
                            <Timeline.Time>{date}</Timeline.Time>
                            {ordens
                                .filter(item => new Date(item.data).toLocaleDateString() === date)
                                .map(ordem =>
                                    <CardAprovacoesItem
                                        key={ordem.id}
                                        ordem={ordem}
                                        stateOrdens={[ordens, setOrdens]}
                                        readonly={props.readonly} />)}
                        </Timeline.Item>
                    )}
                </Timeline>
            </div>
        </div>
    );
};