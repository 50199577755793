import { TipoMovimento, OrdemStatus } from "../enums";
import { BaseModel } from "./BaseModel";
import { ContratoModel } from "./ContratoModel";
import { ClienteModel } from "./ClienteModel";

export interface OrdemModel extends BaseModel {
    clienteId: number,
    cliente?: ClienteModel,
    contratoId: number,
    contrato?: ContratoModel,
    contratoDestinoId?: number,
    data: string,
    executadaEm?: string,
    executadaEmDate: Date | null,
    canceladaEm?: string,
    tipo: TipoMovimento,
    status: OrdemStatus,
    valor: number,
    observacoesCliente?: string,
    justificativaCancelamento?: string,
    comprovanteLink?: string,
    comprovanteFile?: Blob | null,
    comprovanteFilename?: string
}

export const defaultOrdemModel: OrdemModel = {
    clienteId: 0,
    contratoId: 0,
    data: "",
    isSelected: false,
    status: OrdemStatus.Pendente,
    tipo: TipoMovimento.Aporte,
    valor: 0,
    executadaEmDate: null
}