import { AppConfig } from "../env.config";
import { PerfilModel } from "../models/PerfilModel";
import { UsuarioModel } from '../models/UsuarioModel';
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${AppConfig.ApiURL}/usuario`;

export class UsuarioService extends BaseService {
    public static async get(props: ServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<UsuarioModel>> {
        return await this.GET<UsuarioModel>(`${ENDPOINT}?limit=${props.limit}&page=${props.page}`);
    }

    public static async getId(id: number, props: ServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<UsuarioModel>> {
        return await this.GET<UsuarioModel>(`${ENDPOINT}/${id}?limit=${props.limit}&page=${props.page}`);
    }

    public static async add(model: UsuarioModel): Promise<DefaultResponse<UsuarioModel>> {
        return await this.POST<UsuarioModel>(`${ENDPOINT}`, model);
    }

    public static async update(model: UsuarioModel): Promise<DefaultResponse<UsuarioModel>> {
        return await this.PATCH<UsuarioModel>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async delete(id: number): Promise<DefaultResponse<UsuarioModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }

    public static async atualizarPerfil(model: PerfilModel, file?: File | null): Promise<DefaultResponse> {

        const formData = new FormData();
        if (file !== undefined && file !== null)
            formData.append("foto", file);
        formData.append("perfil", JSON.stringify(model));
        return await this.PATCHFORM(`${ENDPOINT}/${model.id}/perfil`, formData);
    }

    public static async resetPassword(id: number): Promise<DefaultResponse> {
        return await this.POST(`${ENDPOINT}/${id}/reset-password`, null);
    }
}