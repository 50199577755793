import { FC } from "react";
import { FaUserFriends, FaToolbox, FaUser, FaCalculator } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useInvestmentCalculatorContext } from "../../../contexts/InvestmentCalculatorContext";
import { useSessionContext } from "../../../contexts/SessionContext";
import { ROUTE_CUSTOMERS, ROUTE_CUSTOMERS_GROUPS, ROUTE_DEALERS, ROUTE_USERS } from "../../../util/AppRoutes";
import { SessionManager } from "../../../util/SessionManager";
import { SidebarDropdownListItems } from "../DropdownListItems";
import { SideBarItem } from "../Item";
import { SidebarProps } from "../types";

export const SidebarAdminItems: FC<SidebarProps> = (props) => {
    const { isShowing, showCalculator } = useInvestmentCalculatorContext();
    const currentLocation = useLocation();
    const sessionContext = useSessionContext();
    const isRouteActive = (expected: string): boolean => {
        return currentLocation.pathname.includes(expected)
    }

    const onSidebarItemClick = (event?: () => void) => {
        SessionManager.setSession({ ...SessionManager.getSession(), hiddenSidebar: true });
        sessionContext.refreshSession();

        event?.call(this);
        props.onItemClick?.call(this);
    }

    return (
        <>
            <SideBarItem onClick={() => onSidebarItemClick(() => showCalculator(!isShowing))} label="Calculadora" icon={<FaCalculator className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />} />

            <hr />

            <SideBarItem onClick={onSidebarItemClick} to={ROUTE_DEALERS} label="Consultores" icon={<FaToolbox className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />} />

            <SidebarDropdownListItems label="Clientes" expanded={isRouteActive('/customers')} icon={<FaUserFriends className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />}>
                <SideBarItem onClick={onSidebarItemClick} to={ROUTE_CUSTOMERS_GROUPS} dropdown={true} label="Grupos" />
                <SideBarItem onClick={onSidebarItemClick} to={ROUTE_CUSTOMERS} dropdown={true} label="Meus Clientes" />
            </SidebarDropdownListItems>

            <SideBarItem onClick={onSidebarItemClick} to={ROUTE_USERS} label="Usuários" icon={<FaUser className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />} />
        </>
    );
};