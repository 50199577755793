import { TipoUsuario } from "../enums";
import { FluentValidator } from "../types";
import CodeUtil from "../util/CodeUtil";
import { UsuarioModel } from "./UsuarioModel";

export interface ConsultorModel extends UsuarioModel {
    usuarioId: number;
    celular?: string;
    observacoes?: string;    
}

export const defaultConsultor : ConsultorModel = {
    usuarioId: 0,
    nome: '',
    email: '',
    nomeUsuario: '',
    tipoUsuario: TipoUsuario.CONSULTOR,
    isSelected: false,    
    isBloqueado: false
}

export const validateConsultor = (model: ConsultorModel) : FluentValidator[] => {
    let result: FluentValidator[] = []; 

    if (CodeUtil.isNullOrEmpty(model.nome?.trim()))
        result.push({ field: 'nome', message: 'O nome do consultor deve ser informado', isValid: false });

    if (CodeUtil.isNullOrEmpty(model.email?.trim())) 
        result.push({ field: 'email', message: 'O e-mail do consultor deve ser informado.', isValid: false });
    else if (!CodeUtil.isValidEmail(model.email?.trim()))
        result.push({ field: 'email', message: 'O e-mail informado não é um e-mail válido.', isValid: false });

    return result;
}