import { FC, useState } from "react";
import { CodeTabbedTitleDivider } from "./components/CodeTabbedTitleDivider";
import { CodeTabbedTitleItem } from "./components/CodeTabbedTitleItem";
import { CodeTabbedTitleProps } from "./types";

export const CodeTabbedTitle: FC<CodeTabbedTitleProps> = (props) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(props.initialIndex ?? 0);

    const handleItemChange = (index: number) => {
        setSelectedIndex(index);
        props.onChange?.call(this, index);
    }

    return (
        <>
            <div className="block">
                <div className="flex">
                    {props.items.map((item, index) => <CodeTabbedTitleItem key={index} value={item} active={index === selectedIndex} />)}
                </div>

                <div className="flex space-x-2">
                    {props.items.map((_item, index) => <CodeTabbedTitleDivider key={index} onClick={handleItemChange}
                        itemIndex={index}
                        active={index === selectedIndex} />)}
                </div>
            </div>
        </>
    );
};