import { Dropdown, DropdownProps } from "flowbite-react";
import styled from "styled-components";

const FlatDrowpdown: React.FC<DropdownProps> = ({ ...props }) => {
    return (
        <StyledFlatDropDown className="hover:bg-slate-200 active:bg-slate-300 rounded-lg border border-none">
            <Dropdown color="none" size="sm" {...props}>
                {props.disabled ? <></> : props.children}
            </Dropdown>
        </StyledFlatDropDown>
    )
}


const StyledFlatDropDown = styled.button`
    border-radius: 0.5rem;
    border: none;
    outline: none;    
    font-size: 0.875rem;
    line-height: 1.25rem;
    align-items: center;
    display: flex;
    text-transform: none;

    > span {
        font-weight: 500;
        text-align: center;
    }

    :hover {
        background-color: rgb(226 232 240);
    }

    :active, :focus {
        background-color: rgb(203 213 225);
    }

    :disabled {
        background-color: rgb(226 232 240);
        opacity: 0.5;
        cursor: no-drop
    }

`

export default FlatDrowpdown;