import { Avatar, Tooltip } from "flowbite-react";
import defaultImage from '../../../images/profile.png';
import { useSidebarAccountContext } from "../../../contexts/SidebarAccountContext";
import { useSessionContext } from "../../../contexts/SessionContext";

const ToolbarAvatar: React.FC = () => {
    const { session } = useSessionContext();
    const { setSidebarAccountHidden } = useSidebarAccountContext();

    return (
        <div className="cursor-pointer ring-2 ring-offset-blue-200 rounded-full">
            <Tooltip content="Meu perfil" arrow={false}>
                <Avatar
                    alt="Meu Perfil"
                    rounded={true}
                    onClick={() => setSidebarAccountHidden(false)}
                    img={session?.usuario?.foto ?? defaultImage} />
            </Tooltip>
        </div>)
}

export default ToolbarAvatar;