import { Route, Routes, Navigate } from "react-router-dom";
import LoginPage from "./account/login/LoginPage";
import React from "react";
import {
  ROUTE_CUSTOMERS, ROUTE_CUSTOMERS_ATTACHMENTS, ROUTE_CUSTOMERS_GROUPS, ROUTE_DEALERS,
  ROUTE_HOME, ROUTE_LOGIN, ROUTE_USERS
} from "../util/AppRoutes";
import MainLayout from "./_layouts/MainLayout";
import UsersPage from "./users";
import { DealerPage } from "./dealers";
import CustomersPage from "./customers";
import CustomersGroupsPage from "./customers_groups";
import useRouteGuard from "../hooks/RouteGuardHook";
import MainPage from "./main/MainPage";
import useUserRole from "../hooks/UserRoleHook";
import { CustomerAttachmentsPage } from "./customers_attachments";

export const App = () => {
  const currentRole = useUserRole();

  return (
    <Routes>
      <Route path={ROUTE_LOGIN} element={<LoginPage />} />
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="*" element={<Navigate to={ROUTE_LOGIN} />} />

      <Route path={ROUTE_HOME} element={useRouteGuard(<MainLayout resetMargin={true} children={<MainPage />} />)} />

      <Route path={ROUTE_CUSTOMERS} element={useRouteGuard(<MainLayout children={<CustomersPage />} />, undefined, currentRole !== "CLIENTE")} />
      <Route path={ROUTE_CUSTOMERS_GROUPS} element={useRouteGuard(<MainLayout children={<CustomersGroupsPage />} />, undefined, currentRole !== "CLIENTE")} />
      <Route path={ROUTE_DEALERS} element={useRouteGuard(<MainLayout children={<DealerPage />} />, undefined, currentRole !== "CLIENTE")} />
      <Route path={ROUTE_USERS} element={useRouteGuard(<MainLayout children={<UsersPage />} />, undefined, currentRole !== "CLIENTE")} />

      <Route path={ROUTE_CUSTOMERS_ATTACHMENTS} element={useRouteGuard(<MainLayout children={<CustomerAttachmentsPage />} />, undefined, currentRole === "CLIENTE")} />
    </Routes>
  )
}
