import { FiEye } from '@react-icons/all-files/fi/FiEye'
import { FiEyeOff } from '@react-icons/all-files/fi/FiEyeOff'
import { Tooltip } from 'flowbite-react';
import { useCallback, useEffect, useState } from 'react';
import { useAccountBalanceContext } from '../../../contexts/AccountBallanceContext';
import { useSessionContext } from '../../../contexts/SessionContext';
import useUserRole from '../../../hooks/UserRoleHook';
import { ContratoSaldoModel } from '../../../models/ContratoSaldoModel';
import { ContratoService } from '../../../services/ContratoService';
import CodeUtil from '../../../util/CodeUtil';
import { UNKOWN_EXCEPTION } from '../../../util/Constants';
import { ShowToast } from '../../CodeToast';

const ToolbarAccountBalance: React.FC = () => {
    const { showAccountBalance, showHideAccountBalance, tipoContrato } = useAccountBalanceContext();
    const currentSession = useSessionContext();
    const currentRole = useUserRole();

    const [loaded, setLoaded] = useState<boolean>(false);
    const [contratoSaldo, setContratoSaldo] = useState<ContratoSaldoModel>({ totalAplicado: 0, totalRendimento: 0, saldo: 0 });
    const [currentContextState, setCurrentContextState] = useState<boolean>(showAccountBalance);

    const atualizarSaldo = useCallback(async () => {

        try {
            if (loaded) return;
            setLoaded(true);

            let response = await ContratoService.getSaldoContrato({
                clienteId: currentRole === "CLIENTE" ? currentSession.session?.usuario?.clienteId : undefined,
                tipoContrato: tipoContrato,
                limit: 0,
                page: 1
            });

            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) })
                return;
            }

            setContratoSaldo(response.data);
        } catch (error) {
            ShowToast({ message: UNKOWN_EXCEPTION })
            console.log(error);
        }

    }, [loaded, currentSession, currentRole, tipoContrato, setLoaded, setContratoSaldo]);


    const checkContextToogled = useCallback(async () => {
        if (currentContextState !== showAccountBalance) {
            setLoaded(false);
            setCurrentContextState(showAccountBalance);
        }
    }, [setLoaded, setCurrentContextState, currentContextState, showAccountBalance]);

    const toogleBalance = () => showHideAccountBalance(!showAccountBalance);

    useEffect(() => {
        checkContextToogled();
        atualizarSaldo();
    }, [checkContextToogled, atualizarSaldo])


    return (
        <div className="hidden md:inline-flex md:cursor-help">
            <div className='block'>
                <Tooltip content={(<div>Aportes: {CodeUtil.moneyFormat(contratoSaldo.totalAplicado)}
                    <br />Rendimentos: {CodeUtil.moneyFormat(contratoSaldo.totalRendimento)}</div>)}>
                    <p className="leading-lose font-semibold text-slate-400 text-xs">Saldo</p>
                    {showAccountBalance ? <h1 className="font-bold">{CodeUtil.moneyFormat(contratoSaldo.saldo)}</h1> :
                        <div className='rounded bg-slate-200 h-5 w-24'></div>}
                </Tooltip>
            </div>

            <div className='ml-3 mt-5 image-button' >
                {showAccountBalance ?
                    <FiEye onClick={toogleBalance} className='color-atac-accent' /> :
                    <FiEyeOff onClick={toogleBalance} className='color-atac-accent' />}
            </div>
        </div>
    )
}

export default ToolbarAccountBalance;