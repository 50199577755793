import { FC } from 'react';
import logoWhite from '../../../images/logo.png';

export const ToolbarBrand: FC = () => {
  return (
    <a href="https://app.atacgestaodenegocios.com.br/" className="flex items-center">
      <img src={logoWhite} className="mr-3 h-8 lg:h-8" alt="Code Logo" />
    </a>
  );
};
