import { FC } from "react";
import styled from "styled-components";
import { CodeTabbedTitleItemProps } from "../../types";

export const CodeTabbedTitleItem: FC<CodeTabbedTitleItemProps> = (props) => {
    return (
        <StyledItem>
            <h2 className="font-bold">{!!props.active ? props.value : ""}</h2>
        </StyledItem>
    )
}

const StyledItem = styled.div`
    h2 {
        height: 1.35rem;        
        transition: value .3s ease-in;    
    }
`