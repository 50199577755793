import { Tabs } from "flowbite-react";
import React from "react";
import styled from "styled-components";
import { MainDashboard } from "./components/MainDashboard";

const MainPage: React.FC = () => {
  return (
    <StyledMainPage>
      {/* eslint-disable-next-line */}
      <Tabs.Group aria-label="Abas" id="tab-group" style="underline">
        <Tabs.Item title="Tradicional">
          <MainDashboard tipoContrato="Tradicional" />
        </Tabs.Item>
        <Tabs.Item title="Carteira 2">
          <MainDashboard tipoContrato="Carteira 2" />
        </Tabs.Item>
      </Tabs.Group>
    </StyledMainPage>
  );
};

const StyledMainPage = styled.div`
  @media (max-width: 1024px) {
    margin-top: 4.58rem;
  }

  #tab-group {  
    background-color: #002d5d
  }
  
  #tab-group > button {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    transition: border, color .3s ease-in;
  }

  #tab-group > button[aria-selected="true"] {
    color: rgba(60, 191, 174);
    border-color: rgba(60, 191, 174);
  };

  #tab-group > button:hover {
    border-color: rgba(60, 191, 174);
  };

  #tab-group > button[aria-selected="false"] {
    color: rgba(255, 255, 255, 0.88);
  };
`

export default MainPage
