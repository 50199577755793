import { ModelState } from "../types";
import { BaseModel } from "./BaseModel";
import { ClienteModel } from "./ClienteModel";

export type ContratoStatus = "Vigente" | "Cancelado" | "Encerrado";
export type ContratoTipo = "Tradicional" | "Carteira 2";

export interface ContratoModel extends BaseModel {
    id: number,
    clienteId: number,
    cliente?: ClienteModel,
    vigencia?: string | null,
    status: ContratoStatus,
    tipo: ContratoTipo,
    state: ModelState,
    totalEmCaixa: number,
    totalAplicado: number,
    rendimentoDisponivel: number
}

export const defaultContratoModel: ContratoModel = {
    clienteId: 0,
    id: 0,
    isSelected: false,
    status: "Vigente",
    tipo: "Tradicional",
    state: "UNCHANGED",
    totalEmCaixa: 0,
    totalAplicado: 0,
    rendimentoDisponivel: 0
}