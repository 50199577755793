import styled from "styled-components";
import { InvestmentCalculator } from "../../components/InvestmentCalculator";
import { Sidebar } from "../../components/Sidebar";
import Toolbar from "../../components/Toolbar";
import { useSessionContext } from "../../contexts/SessionContext";
import { SessionManager } from "../../util/SessionManager";
import { LayoutProps } from "./types";
import '../../index.css';
import { SidebarAccount } from "../../components/SidebarAccount";

const MainLayout: React.FC<LayoutProps> = ({ children, resetMargin }) => {
  const { session } = useSessionContext();

  return (
    <div>
      <div className="flex">
        <Toolbar />

        <Sidebar
          hidden={(session?.hiddenSidebar ?? true)}
          onItemClick={() =>
            SessionManager.setSession({
              ...SessionManager.getSession(),
              hiddenSidebar: true,
            })
          }
        />

        <SidebarAccount />

        <div className="w-full">
          {!!resetMargin ? <StyledSideContainerReset>{children}</StyledSideContainerReset> : <StyledSideContainer>{children}</StyledSideContainer>}
          <InvestmentCalculator />
        </div>
      </div>
    </div>
  );
};


const StyledSideContainer = styled.div`
  margin: 4rem 2rem 2rem 2rem;

  @media (min-width: 1024px) {
      margin: 4rem 4rem 2rem 4rem;
  }
`

const StyledSideContainerReset = styled.div`
  margin: 6.58rem 0rem 0rem 0rem;  

  @media (min-width: 481px) {
      margin: 4rem 0rem 0rem 0rem;
  }
`

export default MainLayout;
