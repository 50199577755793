import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { HiCloudDownload } from "react-icons/hi";
import Spinner from "../../components/CodeSpinner";
import { ShowToast } from "../../components/CodeToast";
import DataGrid from "../../components/DataGrid";
import { useSessionContext } from "../../contexts/SessionContext";
import { ClienteDocumentoModel } from "../../models/ClienteDocumentoModel";
import { ClienteDocumentoService } from "../../services/ClienteDocumentoService";
import { ResponsePagination } from "../../types";
import CodeUtil from "../../util/CodeUtil";
import { UNKOWN_EXCEPTION } from "../../util/Constants";

export const CustomerAttachmentsPage: FC = () => {
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<ClienteDocumentoModel[]>([]);
    const sessionUser = useSessionContext().session?.usuario;
    const [pagination, setPagination] = useState<ResponsePagination>({ pageCount: 0, pageIndex: 1 });

    const fetchAttachments = useCallback(async () => {
        try {
            setDataSource([]);
            var response = await ClienteDocumentoService.get({
                clienteId: sessionUser?.clienteId ?? -1,
                limit: 100,
                page: pagination.pageIndex
            });

            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                return;
            }

            setDataSource(response.data);
            setPagination({ pageCount: response.pagination.pageCount, pageIndex: response.pagination.page });
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        }
    }, [setDataSource, sessionUser, pagination]);

    const onFormLoad = useCallback(async () => {
        try {
            if (show) return;
            setLoading(true);

            await fetchAttachments();

            setShow(true);
        } finally {
            setLoading(false);
        }
    }, [show, setShow, setLoading, fetchAttachments]);

    const handleFormatDataColumn = (value: any, _object?: any): ReactNode | undefined => {
        return <span>{CodeUtil.isNullOrEmpty(value) ? "-" : new Date(`${value}Z`).toLocaleDateString()}</span>;
    }

    const handleFormatLinkColumn = (value: any, _object?: ClienteDocumentoModel): ReactNode | undefined => {
        return <a href={value} target="_blank" rel="noreferrer">
            <HiCloudDownload className="w-5 h-5 color-atac-accent" />
        </a>;
    }

    useEffect(() => {
        onFormLoad();
    }, [onFormLoad]);

    return (
        <>
            <Spinner hidden={!loading} fullPage={true} />

            <div className='mt-5' hidden={loading}>
                <DataGrid
                    multiSelect={true}
                    search={false}
                    readOnly={true}
                    sortDirection="asc"
                    sortedColumn='data'
                    keyColumn='id'
                    columns={[
                        { propertyName: 'id', title: '#' },
                        { propertyName: 'data', title: 'Anexado Em', columnAlignment: "center", onFormat: (prop, obj) => handleFormatDataColumn(prop, obj) },
                        { propertyName: 'nomeArquivo', title: 'Arquivo' },
                        { propertyName: 'link', title: 'Baixar', columnAlignment: "center", onFormat: (prop, obj) => handleFormatLinkColumn(prop, obj) },
                    ]}
                    data={dataSource}
                    caption={{
                        title: 'Anexos',
                        resume: 'Lista de documentos e relatórios',
                    }}
                    pagination={{
                        pageCount: pagination.pageCount,
                        pageIndex: pagination.pageIndex,
                        limit: 100,
                        onPageChange: (pageIndex) => setPagination({ ...pagination, pageIndex })
                    }}
                />
            </div>
        </>
    );
};