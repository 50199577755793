import { TextInput } from 'flowbite-react';
import React from 'react'
import {FiSearch} from 'react-icons/fi'

export interface DataGridCaptionProps {
    title?: string
    resume?: string | JSX.Element | any
    search?: boolean
    sortedColumn?: string
    children?: JSX.Element | JSX.Element[] | any
    onSearchChanged?: (text?: string) => void
}

const DataGridCaption : React.FC<DataGridCaptionProps> = (props) => {
  return (
    <caption className="py-3 px-5 bg-gray-50">
        <div className='md:flex sm:block'>
          <div className='text-lg font-semibold text-left text-gray-900'>
            {props.title}
            <p className="mt-1 text-sm font-normal text-gray-500">{props.resume}</p>
          </div>
          {props.search ? 
          (<div className='items justify-end mt-2 sm:mt-5 sm:block ml-auto'>
            <TextInput sizing="sm" type="text"
               icon={FiSearch}
               placeholder={`Filtrar por ${props.sortedColumn}`} onChange={(e) => props.onSearchChanged?.call(this, e.currentTarget.value)} />
          </div>) : <></>}
        </div>

        {props.children}
    </caption>
  )
}

export default DataGridCaption
