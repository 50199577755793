import { Badge, Tooltip } from "flowbite-react";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { FiDownloadCloud } from "react-icons/fi";
import { RiFileTextLine } from "react-icons/ri";
import { ShowToast } from "../../../../components/CodeToast";
import DataGrid from "../../../../components/DataGrid";
import ModalForm from "../../../../components/ModalForm";
import { Popup } from "../../../../components/Popup";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { FormStateType, TipoMovimento, OrdemStatus } from "../../../../enums";
import { OrdemModel } from "../../../../models/OrdemModel";
import { OrdemService } from "../../../../services/OrdemService";
import CodeUtil from "../../../../util/CodeUtil";
import { UNKOWN_EXCEPTION } from "../../../../util/Constants";
import { PopupHistoricoOrdensProps } from "../CardAprovacoes/types";

export const PopupHistoricoOrdens: FC<PopupHistoricoOrdensProps> = (props) => {
    const handleModalClose = () => {
        setPagination({ ...pagination, refresh: true });
        props.onClose?.call(this);
    }
    const [status] = useState<OrdemStatus[]>([OrdemStatus.Executada, OrdemStatus.Cancelada]);
    const [data, setData] = useState<OrdemModel[]>([]);
    const [pagination, setPagination] = useState<{ page: number, pageCount: number, refresh: boolean }>({ page: 1, pageCount: 1, refresh: true });
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [model, setModel] = useState<OrdemModel>();
    const currentSession = useSessionContext();
    const isUsuarioCliente = currentSession.session?.usuario?.tipoUsuario === "CLIENTE";

    const buscarOrdens = useCallback(async () => {
        try {
            if (!props.show || !pagination.refresh) return;

            let response = await OrdemService.get({
                limit: 100,
                page: pagination.page,
                status: status,
                clienteId: isUsuarioCliente ?
                    currentSession.session?.usuario?.clienteId : undefined,
                tipoContrato: props.tipoContrato
            });

            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                return;
            }

            setPagination({
                page: response.pagination.page,
                pageCount: response.pagination.pageCount,
                refresh: false
            })
            setData(response.data);
        } catch (error) {
            ShowToast({ message: UNKOWN_EXCEPTION });
            console.log(error);
        }
    }, [pagination, props, status, currentSession, isUsuarioCliente]);

    useEffect(() => { buscarOrdens() }, [buscarOrdens]);

    const onFormatDateColumn = (value: string): ReactNode => {
        return <span>{CodeUtil.isNullOrEmpty(value) ? "-" : new Date(`${value}Z`).toLocaleString()}</span>;
    }

    const onFormatDataExecucaoColumn = (value: string): ReactNode => {
        return <span>{CodeUtil.isNullOrEmpty(value) ? "-" : new Date(`${value}Z`).toLocaleDateString()}</span>;
    }

    const onFormatMoneyColumn = (value: number): ReactNode => {
        return <span>{CodeUtil.moneyFormat(value)}</span>;
    }

    const onFormatStatusColumn = (value: OrdemStatus): ReactNode => {
        return (
            <span className="flex w-auto justify-center">
                {value === OrdemStatus.Executada ? <Badge color="success">{value}</Badge> : <Badge color="failure">{value}</Badge>}
            </span>
        );
    }

    const onFormatTipoColumn = (value: TipoMovimento): ReactNode => {
        if (value === TipoMovimento.SaqueAporte || value === TipoMovimento.SaqueRendimento) {
            return (
                <span className="flex w-auto justify-center">
                    <Badge color="failure">{value}</Badge>
                </span>
            );
        };

        if (value === TipoMovimento.Rendimento) {
            return (
                <span className="flex w-auto justify-center">
                    <Badge color="success">{value}</Badge>
                </span>
            );
        };

        if (value === TipoMovimento.Transferencia) {
            return (
                <span className="flex w-auto justify-center">
                    <Badge color="warning">{value}</Badge>
                </span>
            );
        };

        return (
            <span className="flex w-auto justify-center">
                <Badge color="info">{value}</Badge>
            </span>
        );
    }

    const onFormatButtonJustificativa = (value: OrdemModel): ReactNode => {
        if (CodeUtil.isNullOrEmpty(value.justificativaCancelamento) && CodeUtil.isNullOrEmpty(value.observacoesCliente)) return <></>;

        return (
            <div className="flex items-center cursor-pointer">
                <Tooltip content="Observações">
                    <RiFileTextLine className='color-atac-accent mr-4 w-5 h-5' onClick={() => {
                        setShowPopup(true);
                        setModel(value);
                    }} />
                </Tooltip>
            </div>
        );
    }

    const onFormatButtonComprovante = (value: OrdemModel): ReactNode => {
        if (CodeUtil.isNullOrEmpty(value.comprovanteLink)) return <></>;

        return (
            <div className="flex items-center cursor-pointer">
                <a className="ml-auto -mt-1" href={value.comprovanteLink} target="_blank" rel="noreferrer">
                    <Tooltip content="Baixar comprovante" arrow={false}>
                        <FiDownloadCloud className="mr-4 w-5 h-5 color-atac-accent" />
                    </Tooltip>
                </a>
            </div>
        );
    }

    return (
        <>
            <ModalForm {...props} title="Histórico de Ordens"
                state={FormStateType.view}
                size="7xl"
                hideHeader={true}
                onClose={handleModalClose}>

                <DataGrid
                    keyColumn="id"
                    data={data}
                    multiSelect={false}
                    readOnly={true}
                    sortedColumn={"id"}
                    sortDirection={"desc"}
                    search={true}
                    caption={{ title: 'Histórico de Ordens', resume: 'Listagem de todas as ordens executadas e canceladas' }}
                    columns={[
                        { propertyName: "id", title: "ID", columnAlignment: "right", onFormat(prop) { return <span>{String(prop).padStart(3, "0")}</span> } },
                        { propertyName: "status", title: "Situação", columnAlignment: "center", onFormat(prop) { return onFormatStatusColumn(prop) } },
                        { propertyName: "tipo", title: "Tipo", columnAlignment: "center", onFormat(prop) { return onFormatTipoColumn(prop) } },
                        { propertyName: "cliente.nomeCompleto", title: "Cliente" },
                        { propertyName: "data", title: "Registrada Em", columnAlignment: "center", onFormat(prop) { return onFormatDateColumn(prop) } },
                        { propertyName: "executadaEm", title: "Executada Em", columnAlignment: "center", onFormat(prop) { return onFormatDataExecucaoColumn(prop) } },
                        { propertyName: "canceladaEm", title: "Cancelada Em", columnAlignment: "center", onFormat(prop) { return onFormatDateColumn(prop) } },
                        { propertyName: "valor", title: "Valor", columnAlignment: "right", onFormat(prop) { return onFormatMoneyColumn(prop) } },
                        { propertyName: "", title: "", columnAlignment: "center", onFormat(_prop, model) { return onFormatButtonJustificativa(model) } },
                        { propertyName: "", title: "", columnAlignment: "center", onFormat(_prop, model) { return onFormatButtonComprovante(model) } },
                    ]}
                    pagination={{
                        pageCount: pagination.pageCount,
                        pageIndex: pagination.page,
                        limit: 100,
                        onPageChange: (pageIndex) => setPagination({ ...pagination, page: pageIndex, refresh: true })
                    }} />
            </ModalForm>

            <Popup
                isShowing={showPopup}
                message={(model?.status === OrdemStatus.Cancelada ? model?.justificativaCancelamento : model?.observacoesCliente) ?? ""}
                isDestructive={false}
                viewOnly={true}
                showIcon={false}
                onCancel={() => setShowPopup(false)} />
        </>
    );
};