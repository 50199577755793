import { Badge, Checkbox, Timeline, Tooltip } from "flowbite-react";
import { ChangeEvent, FC } from "react";
import { FiDownloadCloud } from "react-icons/fi";
import { useAccountBalanceContext } from "../../../../../../contexts/AccountBallanceContext";
import CodeUtil from "../../../../../../util/CodeUtil";
import { CardAprovacoesItemProps } from "../../types";

export const CardAprovacoesItem: FC<CardAprovacoesItemProps> = (props) => {
    const [ordens, setOrdens] = props.stateOrdens;
    const { showAccountBalance: isShowingBalance } = useAccountBalanceContext();

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setOrdens(ordens.map(ordem => {
            if (ordem.id === props.ordem.id)
                ordem.isSelected = e.currentTarget.checked;
            return ordem;
        }));

        props.onCheckChange?.call(this, e);
    }

    return (
        <Timeline.Content>
            <div className="mb-3">
                <div className="flex">
                    <span className="text-sm font-semibold">{props.ordem.cliente?.nomeCompleto}</span>
                    {CodeUtil.isNullOrEmpty(props.ordem.comprovanteLink) ? <></> :
                        <a className="ml-auto -mt-1" href={props.ordem.comprovanteLink} target="_blank" rel="noreferrer">
                            <Tooltip content="Baixar comprovante" arrow={false}>
                                <FiDownloadCloud className="w-5 h-4 color-atac-accent" />
                            </Tooltip>
                        </a>}
                </div>
                <span className="flex">
                    {isShowingBalance ?
                        <span className="text-sm">{CodeUtil.moneyFormat(props.ordem.valor)}</span> :
                        <span className='rounded bg-slate-200 h-5 w-24'></span>}
                    <span className="ml-auto flex space-x-3 items-center">
                        <Badge color="warning">{props.ordem.tipo}</Badge>
                        {props.readonly ? <></> : <Checkbox hidden={props.readonly}
                            checked={!!props.ordem.isSelected}
                            onChange={(e) => handleCheckboxChange(e)} />}
                    </span>
                </span>
            </div>
        </Timeline.Content>
    );
};