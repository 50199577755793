import { FC } from "react";
import { CardAprovacoesFooterProps } from "../../types";

export const CardAprovacoesFooter: FC<CardAprovacoesFooterProps> = (props) => {

    if (props.hidden) return <></>;

    return (
        <>
            <hr />

            <div className="flex mt-3">
                {props.children}
            </div>
        </>
    );
};