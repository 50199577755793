import { Checkbox, Dropdown } from 'flowbite-react';
import React, { useCallback, useEffect, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { BaseModel } from '../../models/BaseModel';
import DataGridCaption from './Caption';
import DataGridPagination from './Pagination';
import SortableTableHeadCell from './SortableTableCell';
import DataGridProps from './types';
import styled from 'styled-components';
import CodeUtil from '../../util/CodeUtil';

const DataGrid: React.FC<DataGridProps> = ({ multiSelect = false, columns = [], data = [],
    keyColumn, actionItems, ...props }) => {

    const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);
    const [sortedColumn, setSortedColumn] = useState<string | undefined>(props.sortedColumn);
    const [sortedColumnTitle, setSortedColumnTitle] = useState<string | undefined>(props.sortedColumn);

    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(props.sortDirection ?? 'asc');
    const handlerCheckAll: React.MouseEventHandler<HTMLInputElement> = (e) => { setIsCheckedAll(!isCheckedAll); }
    const [query, setQuery] = useState<string | undefined>(undefined);
    const [rows, setRows] = useState<JSX.Element[]>([]);
    const [, setSelectedModels] = useState<BaseModel[]>([]);

    const onSearchChanged = (text?: string) => {
        setQuery(text);
        setRows(renderRows());
    }

    const countColumns = () => {
        let count = 1; //Checkbox
        count += columns?.length ?? 0;
        count += (actionItems?.length ?? 0) > 0 ? 1 : 0;

        return count;
    }

    const renderActionItems = useCallback((row: any) => {

        return (actionItems?.map((item, idx) => {
            if (Boolean(item.isSeparator ?? false)) {
                return <Dropdown.Divider key={idx} />
            } else {
                return (
                    <Dropdown.Item key={idx} onClick={() => item.onClick?.call(this, row[keyColumn])}>
                        <span className={item.isDanger ? 'text-red-700' : ''}>{item.title}</span>
                    </Dropdown.Item>
                )
            }
        })
        );

    }, [keyColumn, actionItems]);

    const renderColumns = () => {
        return (columns.map((column) => (
            <SortableTableHeadCell
                align={column.columnAlignment ?? 'left'}
                key={column.propertyName}
                title={column.title}
                isSorted={sortedColumn === column.propertyName}
                sortDirection={sortDirection}
                onSortColumn={() => {
                    setSortedColumn(column.propertyName)
                    setSortedColumnTitle(column.title.toLocaleLowerCase())
                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
                }}
            />
        )));
    }

    const renderData = useCallback((row: any): JSX.Element[] => {
        return columns.map((column, cellIndex) => (
            <td className='py-2 px-6 whitespace-nowrap' align={column.columnAlignment ?? 'left'} key={cellIndex}>
                {column.onFormat !== undefined ?
                    column.onFormat(CodeUtil.getPropertyValue(row, column.propertyName), row) :
                    CodeUtil.getPropertyValue(row, column.propertyName)}
            </td>
        ));
    }, [columns]);

    const renderRows = useCallback((): JSX.Element[] => {

        const filteredData = CodeUtil.isNullOrEmpty(query) ? data :
            data.filter((value) => String(value[sortedColumn ?? ''])
                .toLocaleLowerCase()
                .normalize('NFD').replace(/\p{Diacritic}/gu, "")
                .indexOf(query!.toLocaleLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "").trim()) >= 0);

        const sortedData = [...filteredData].sort((a, b) =>
            CodeUtil.isNumeric(a[sortedColumn ?? ''] ?? '') ? (sortDirection === 'asc' ? a[sortedColumn ?? ''] - b[sortedColumn ?? ''] : b[sortedColumn ?? ''] - a[sortedColumn ?? '']) :
                a[sortedColumn ?? ''] === b[sortedColumn ?? ''] ? 1 : -1 * (sortDirection === 'asc' ? 1 : -1));

        return (sortedData.map((row, rowIndex) => (
            <tr className='bg-white border-b hover:bg-gray-100' key={rowIndex}>
                {props.readOnly ? (<></>) :
                    (<td className='text-center' align='center' key={-1}>
                        <Checkbox style={{ cursor: 'pointer' }} checked={row?.isSelected ?? false} onChange={(ev) => {
                            data.forEach(_ => _.isSelected = false);
                            row.isSelected = ev.currentTarget.checked;
                            setSelectedModels(row.isSelected ? [row] : []);
                            props.onRowSelect?.call(this, row.isSelected ? [row] : []);
                        }} />
                    </td>)}

                {renderData(row)}

                {actionItems !== undefined && actionItems !== null ?
                    (<td data-bind-id={row[keyColumn]} align='center' key={'action_cell_' + rowIndex}>
                        <div className='flex w-5 h-5 p-4 rounded-full hover:bg-gray-200 active:bg-gray-300 justify-center items-center'>
                            <Dropdown arrowIcon={false} inline={true} label={<FiMoreVertical className='w-5 h-5 text-black/75' />} >
                                {renderActionItems(row)}
                            </Dropdown>
                        </div>
                    </td>) :
                    (<></>)}
            </tr>
        )));

    }, [data, query, sortedColumn, sortDirection, keyColumn, actionItems, props.readOnly,
        setSelectedModels, props.onRowSelect, renderData, renderActionItems]);

    useEffect(() => {
        setRows(renderRows())
    }, [renderRows, setRows])

    return (
        <StyledDataGrid className={`overflow-x-auto ${props.overflowY} relative shadow-md sm:rounded-lg`}>
            <table className="w-full text-sm text-left text-gray-500">
                {Boolean(props.caption) ?
                    (<DataGridCaption
                        title={props.caption?.title}
                        resume={props.caption?.resume}
                        search={props.search ?? true}
                        sortedColumn={sortedColumnTitle}
                        children={props.caption?.children}
                        onSearchChanged={onSearchChanged} />) :
                    (<></>)
                }

                <thead className="text-xs text-gray-700 uppercase bg-gray-50 border-t-2 border-t-gray-100 divide-y">
                    <tr>
                        {props.readOnly ? (<></>) :
                            (<th scope='col' className='py-3 px-6' key='checkbox-column'>
                                {multiSelect ? <Checkbox style={{ cursor: 'pointer' }} onClick={handlerCheckAll} /> : <div></div>}
                            </th>)}

                        {renderColumns()}

                        {actionItems !== undefined && actionItems !== null ?
                            (<th className="text-center" align='center' key='action-column'>Ações</th>) :
                            (<></>)}
                    </tr>
                </thead>

                <tbody className='divide-y'>
                    {rows}
                </tbody>


                {Boolean(props.pagination) ?
                    (<DataGridPagination
                        columnCount={countColumns()}
                        pageCount={props.pagination?.pageCount ?? 1}
                        limit={props.pagination?.limit ?? 100}
                        pageIndex={props.pagination?.pageIndex ?? 1}
                        onPageChange={props.pagination?.onPageChange} />) :
                    (<></>)}
            </table>

        </StyledDataGrid>
    )
}


export default DataGrid;


const StyledDataGrid = styled.div`
    th {
        cursor: pointer;
    }

    thead > tr {
        border-bottom: 1.2px solid #E5E7EB;
        user-select: none;
    }

    .table-footer {
        border-top: 1.2px solid #E5E7EB;
    }
`