import { AppConfig } from "../env.config";
import { ClienteGrupoModel } from "../models/ClienteGrupoModel";
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${AppConfig.ApiURL}/cliente_grupo`;

export class ClienteGrupoService extends BaseService {
    public static async get(props: ServiceProps = { limit: 100, page: 1 }) : Promise<DefaultResponse<ClienteGrupoModel>> {
        return await this.GET<ClienteGrupoModel>(`${ENDPOINT}?limit=${props.limit}&page=${props.page}`);
    }

    public static async getId(id: number, props: ServiceProps = { limit: 100, page: 1 }) : Promise<DefaultResponse<ClienteGrupoModel>> {
        return await this.GET<ClienteGrupoModel>(`${ENDPOINT}/${id}?limit=${props.limit}&page=${props.page}`);
    }

    public static async add(model: ClienteGrupoModel) : Promise<DefaultResponse<ClienteGrupoModel>> {
        return await this.POST<ClienteGrupoModel>(`${ENDPOINT}`, model);
    }

    public static async update(model: ClienteGrupoModel) : Promise<DefaultResponse<ClienteGrupoModel>> {
        return await this.PATCH<ClienteGrupoModel>(`${ENDPOINT}/${model.id}`, model);
    }

    public static async delete(id: number) : Promise<DefaultResponse<ClienteGrupoModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}