import { FC } from "react";
import CTAPanel from "../../../../components/CTAPanel";
import { CTAPanelButtonType } from "../../../../components/CTAPanel/types";
import { ClienteGrupoModel } from "../../../../models/ClienteGrupoModel";
import { SearchCTAProps } from "../../../../types";

export const CustomersGroupsCTA: FC<SearchCTAProps<ClienteGrupoModel | null>> = (props) => {
  const [model, ] = props.modelState;

  return (
    <CTAPanel
      buttons={[
        {
          label: "Inserir",
          type: CTAPanelButtonType.CTA,
          action: () => props.onAdd?.call(this),
        },
        {
          label: "Editar",
          type: CTAPanelButtonType.FLAT,
          disabled: model === undefined || model === null,
          action: () => props.onEdit?.call(this),
        },
        {
          label: "Consultar",
          type: CTAPanelButtonType.FLAT,
          disabled: model === undefined || model === null,
          action: () => props.onView?.call(this),
        },
        {
          label: "Excluir",
          type: CTAPanelButtonType.DESTRUCTIVE,
          disabled: model === undefined || model === null,
          action: () => props.onDelete?.call(this),
        },
      ]}
    />
  );
};
