import { AxiosError } from "axios";
import { Checkbox, Label, TextInput } from "flowbite-react";
import { FC, useCallback, useEffect, useState } from "react";
import { ShowToast } from "../../../../components/CodeToast";
import { ModalForm } from "../../../../components/ModalForm";
import { ModalFormProps } from "../../../../components/ModalForm/types";
import { FormStateType } from "../../../../enums";
import { defaultUsuario, UsuarioModel, validateUsuario } from "../../../../models/UsuarioModel";
import { UsuarioService } from "../../../../services/UsuarioService";
import { findValidationField, FluentValidator } from "../../../../types";
import CodeUtil from "../../../../util/CodeUtil";

export const UserModal: FC<ModalFormProps> = (props) => {
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [formLoaded, setFormLoaded] = useState<boolean>(false);
    const [model, setModel] = useState<UsuarioModel>(defaultUsuario);
    const isReadOnly = props.state === FormStateType.view;

    const onFormClose = () => {
        setErrors([]);
        setFormLoaded(false);
        props.onClose?.call(this);
    }

    const onFormLoad = useCallback(async () => {
        if (!props.show || formLoaded) return;

        if (props.id !== undefined && props.id > 0) {
            let response = await UsuarioService.getId(props.id);
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                return;
            }

            setModel(response.data[0]);
        } else setModel(defaultUsuario);

        setFormLoaded(true);
    }, [props.id, props.show, formLoaded, setFormLoaded]);

    const onFormSave = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {

        let validationResult = validateUsuario(model);
        setErrors(validationResult);

        if (validationResult.length > 0) return;

        try {
            setSaving(true);
            let response = props.state === FormStateType.add ? await UsuarioService.add(model) :
                await UsuarioService.update(model);

            if (!response.success) {
                setErrors([{ field: '', isValid: false, message: CodeUtil.arrayToStr(response.messages) }]);
                return;
            }

            props.onSave?.call(this, e);
            onFormClose();
        } catch (error) {
            setErrors([{ field: '', isValid: false, message: (error as AxiosError).response?.data as string }])
        }
        finally {
            setSaving(false);
        }
    }

    useEffect(() => {
        onFormLoad();
    });

    if (!props.show) return <></>;

    return (
        <ModalForm title={props.title}
            errors={errors}
            show={props.show}
            state={props.state}
            onClose={onFormClose}
            isSaving={saving}
            size="2xl"
            onSave={async (e) => await onFormSave(e)}>

            <div className="grid grid-cols-12 space-y-5 p-4">
                <div className="col-span-12">
                    <h2 className="ml-auto text-xl font-extrabold text-black/70" hidden={(model.id ?? 0) === 0}>
                        {`ID: #${(model.id ?? 0).toString().padStart(3, "0")}`}
                    </h2>
                </div>

                <div className="form-control col-span-12 md:col-span-6 md:mr-6">
                    <div className="mb-1"><Label htmlFor="inputNome" value="Nome:" /></div>
                    <TextInput id="inputNome"
                        value={model.nome}
                        maxLength={60}
                        readOnly={isReadOnly}
                        required={true}
                        helperText={findValidationField(errors, "nome").message}
                        color={findValidationField(errors, "nome").isValid ? "gray" : "failure"}
                        onChange={(event) => setModel({ ...model, nome: event.currentTarget.value })}
                        type="text"
                        placeholder="Nome do usuário" />
                </div>

                <div className="form-control col-span-12 md:col-span-6 ">
                    <div className="mb-1"><Label htmlFor="inputSobrenome" value="Sobrenome:" /></div>
                    <TextInput id="inputSobrenome"
                        value={model.sobrenome}
                        maxLength={60}
                        readOnly={isReadOnly}
                        onChange={(event) => setModel({ ...model, sobrenome: event.currentTarget.value })}
                        helperText={findValidationField(errors, "sobrenome").message}
                        color={findValidationField(errors, "sobrenome").isValid ? "gray" : "failure"}
                        type="text"
                        placeholder="Sobrenome do usuário" />
                </div>

                <div className="form-control col-span-12">
                    <div className="mb-1"><Label htmlFor="inputEmail" value="E-mail:" /></div>
                    <TextInput id="inputEmail"
                        value={model.email}
                        maxLength={200}
                        readOnly={isReadOnly}
                        required={true}
                        helperText={findValidationField(errors, "email").message}
                        color={findValidationField(errors, "email").isValid ? "gray" : "failure"}
                        onChange={(event) => setModel({ ...model, email: event.currentTarget.value })}
                        type="email"
                        placeholder="email@email.com.br" />
                </div>

                <div className="form-control col-span-12 md:col-span-6">
                    <div className="mb-1"><Label htmlFor="inputNomeUsuario" value="Login:" /></div>
                    <TextInput id="inputNomeUsuario"
                        value={model.nomeUsuario}
                        maxLength={60}
                        readOnly={isReadOnly}
                        required={true}
                        helperText={findValidationField(errors, "nomeUsuario").message}
                        color={findValidationField(errors, "nomeUsuario").isValid ? "gray" : "failure"}
                        onChange={(event) => setModel({ ...model, nomeUsuario: event.currentTarget.value })}
                        type="text"
                        placeholder="Nome de usuário ou login" />
                </div>

                <div className="col-span-12 flex space-x-2">
                    <Checkbox id="checkBoxBloqueado"
                        disabled={isReadOnly}
                        checked={model.isBloqueado}
                        onChange={(e) => setModel({ ...model, isBloqueado: e.currentTarget.checked })}
                        placeholder="Checked">
                    </Checkbox>

                    <div className="-mt-1" ><Label htmlFor="checkBoxBloqueado" value="Usuário bloqueado?" /></div>
                </div>
            </div>
        </ModalForm>
    );
};

export default UserModal;