/***** Base *****/
export const ROUTE_LOGIN: string = 'account/login';

/***** Account *****/
export const ROUTE_ACCOUNT_CREATE: string = '/account/create-account/';
export const ROUTE_ACCOUNT_ACTIVATE: string = '/account/activate-account/mail/:mail';
export const ROUTE_ACCOUNT_CONFIRM_TOKEN: string = '/account/confirm-account/:token';
export const ROUTE_ACCOUNT_CHANGE_PASSWORD: string = '/account/change-password/account-id/:id';

/***** Account *****/
export const ROUTE_HOME: string = '/home';


/***** Customers *****/
export const ROUTE_CUSTOMERS: string = '/customers/my-customers';
export const ROUTE_CUSTOMERS_GROUPS: string = '/customers/groups';
export const ROUTE_CUSTOMERS_ATTACHMENTS: string = '/customers/attachments';

/***** Dealers *****/
export const ROUTE_DEALERS: string = '/dealers';

/***** Users *****/
export const ROUTE_USERS: string = '/users'; 
