import { Button, Spinner } from "flowbite-react"
import React from "react"
import FlatButton from "../../FlatButton";
import { InvestmentCalculatorModalFooterType } from "../types";

export const InvestmentCalculatorFooter : React.FC<InvestmentCalculatorModalFooterType> = (props) => {
    return (
        <React.Fragment>
            <div className='flex'>
                {props.canPrint ?? false ? <div className='ml-auto mr-2'><FlatButton onClick={props.onExport} label="Exportar"/></div> : <></>}  
                <Button size="sm" gradientDuoTone="cyanToBlue" onClick={props.onCalculate} disabled={props.isLoading}>
                    <div hidden={!props.isLoading} className="mr-3">
                        <Spinner size="sm" light={true} />
                    </div>
                    Calcular
                </Button>
            </div>
        </React.Fragment>
    )
}