import { Button, Spinner } from "flowbite-react";
import { FC, useRef, MouseEvent, ChangeEvent, useState } from "react";
import { FileMiniature } from "../../../../../components/FileMiniature";
import { FormStateType } from "../../../../../enums";
import { ClienteDocumentoModel } from "../../../../../models/ClienteDocumentoModel";
import { CustomersTabProps } from "../../../types";
import { v4 as uuidv4 } from 'uuid';
import { Popup } from "../../../../../components/Popup";
import { ClienteDocumentoService } from "../../../../../services/ClienteDocumentoService";
import { ShowToast } from "../../../../../components/CodeToast";
import CodeUtil from "../../../../../util/CodeUtil";
import { UNKOWN_EXCEPTION } from "../../../../../util/Constants";

export const CustomersTabAnexos: FC<CustomersTabProps> = (props) => {
    const [model, setModel] = props.model;
    const readonly = props.state === FormStateType.view;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedUUID, setUUIDSelecionado] = useState<string | null>();
    const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);

    const handleButtonFileOnClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (fileInputRef.current === undefined) return;
        fileInputRef.current?.click();
    };

    const adicionarParaFila = async (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const anexos: ClienteDocumentoModel[] = [];
            const files = e.target.files;
            if (files?.length === 0) return;

            setUploading(true);

            for (let index = 0; index < (files?.length ?? 0); index++) {
                const file = files?.item(index);

                const newAnexo = await anexarDocumento({
                    clienteId: model.id,
                    nomeArquivo: file?.name ?? "Arquivo sem nome",
                    isSelected: true,
                    uuid: uuidv4(),
                    file: file
                });

                if (newAnexo === undefined) continue;
                anexos.push(newAnexo);
            }

            setModel({ ...model, anexos: [...anexos, ...model.anexos] });
        } finally {
            setUploading(false);
        }
    };

    const anexarDocumento = async (doc: ClienteDocumentoModel): Promise<ClienteDocumentoModel | undefined> => {
        try {
            if (doc.file === undefined || doc.file === null)
                return undefined;

            const response = await ClienteDocumentoService.add(doc);
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                return undefined;
            }

            return response.data;
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
            return undefined;
        }
    }

    const removerItem = async () => {
        try {
            const anexo = model.anexos.filter((_) => _.uuid === selectedUUID)[0];
            const anexos = model.anexos.filter((_) => _.uuid !== selectedUUID);

            if (anexo.id !== 0) {
                const response = await ClienteDocumentoService.delete(anexo.id!);
                if (!response.success) {
                    ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                    return;
                }
            }

            setModel({ ...model, anexos: anexos });
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        } finally {
            setShowPopupDelete(false);
        }
    }

    const showPopupRemoverItem = (item: ClienteDocumentoModel) => {
        setUUIDSelecionado(item.uuid);
        setShowPopupDelete(true);
    }

    return (
        <>
            <input id="inputFile" name="inputFile" className="hidden" type="file" ref={fileInputRef} multiple={true}
                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.numbers,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={adicionarParaFila} />

            {
                model.anexos.length <= 0 ?
                    <div className="flex flex-col space-y-3 ml-auto mr-auto p-5 place-items-center">
                        <span className="text-slate-500">Nenhum anexo para este cliente</span>
                        <Button onClick={handleButtonFileOnClick}
                            disabled={readonly || uploading}
                            gradientDuoTone="cyanToBlue" size="sm">
                            <div hidden={!uploading} className="mr-3">
                                <Spinner size="sm" light={true} />
                            </div>
                            Anexar Arquivo
                        </Button>
                    </div>
                    : <></>
            }

            {
                model.anexos.length === 0 ? <></> :
                    <div className="flex flex-col">
                        <div className="flex flex-wrap mb-5">

                            {model.anexos.map((item) =>
                                <>
                                    <FileMiniature
                                        className="mr-3 mb-3"
                                        showDeleteButton={props.state !== FormStateType.view}
                                        key={item.uuid}
                                        createdDate={new Date(`${item.data}Z` ?? "").toLocaleString()}
                                        link={item.link}
                                        label={item.nomeArquivo}
                                        onDelete={() => showPopupRemoverItem(item)} />
                                </>)}
                        </div>

                        <div className="ml-auto">
                            <Button onClick={handleButtonFileOnClick}
                                disabled={readonly || uploading}
                                gradientDuoTone="cyanToBlue" size="sm">
                                <div hidden={!uploading} className="mr-3">
                                    <Spinner size="sm" light={true} />
                                </div>
                                Anexar Arquivo
                            </Button>
                        </div>
                    </div>
            }

            <Popup
                isShowing={showPopupDelete}
                message="Deseja realmente remover este arquivo? Atenção: Este processo não poderá ser revertido!"
                isDestructive={true}
                onConfirm={async () => await removerItem()}
                onCancel={() => {
                    setUUIDSelecionado(null);
                    setShowPopupDelete(false)
                }} />
        </>
    );
};