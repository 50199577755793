import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default class CodeUtil {
    public static arrayToStr(messages?: string[]): string {
        if (messages === undefined || messages === null)
            return '';

        let message = '';
        message += messages.map((item) => item);

        return message;
    }

    public static exportToCSV(csvData: any, fileName: string) {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    public static arrayLength(data?: string[], ignoreEmpty: boolean = true): number {
        if (data === undefined || data === null)
            return 0;

        if (!ignoreEmpty) {
            return data?.length ?? 0;
        }

        return data.filter((e: string) => e !== undefined && e !== '' && e !== null)?.length ?? 0;
    }

    public static nowToString(): string {
        let date = new Date().toISOString();
        return date.substring(0, date.indexOf('T'));
    }

    public static fromISOString(date?: string, defaultDate?: string): string | undefined {
        return date?.substring(0, date.indexOf('T')) ?? defaultDate;
    }

    public static dateFormat = (value?: string | null): string | null => value ? new Date(value).toLocaleDateString(Intl.Locale.name) : null;

    public static firstOrDefault = <T>(value?: Array<T> | null): T | null => (value?.length ?? 0) === 0 ? null : value![0];

    public static getPropertyValue(row: any, name: string): any {
        let columns = name.split('.');

        if (columns.length <= 0)
            return '';

        if (columns.length === 1)
            return row[columns[0]];

        let obj = row;
        columns.map((column) => obj = obj[column]);
        return obj;
    }

    public static isImageFile = (fileName: string): boolean =>
        fileName.endsWith(".jpg") || fileName.endsWith(".jpeg") || fileName.endsWith(".png") ||
        fileName.endsWith(".bmp") || fileName.endsWith(".webp") || fileName.endsWith(".jfif") ||
        fileName.endsWith(".svg");

    public static isNullOrEmpty = (text?: string | null): boolean => text === undefined || text === null || text?.trim() === '';

    public static isNumeric = (value: any) => !isNaN(parseFloat(value)) && isFinite(value);

    public static isUndefined = (obj?: any | null) => obj === undefined || obj === null;

    public static isValidEmail(email?: string): boolean {
        if (email === null)
            return false;

        return email?.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null;
    }

    public static isValidDate(value?: string | null): boolean {
        try {
            if (this.isNullOrEmpty(value)) return false;
            return new Date(value ?? '').toString() !== 'Invalid Date';
        } catch (error) {
            return false;
        }
    }

    public static mask(value: string | null, mask: string = "", symbol: string = "#") {
        if (this.isNullOrEmpty(value)) return "";

        let maskLength = Array.from(mask).filter((val) => val === symbol).length;
        if (value!.length < maskLength)
            return this.removeSpecialChars(value);

        let maskedData = mask;
        Array.from(value!).map((char) => maskedData = maskedData.replace(symbol, char));
        return maskedData;
    }

    public static moneyFormat = (value: number | any, cipher: boolean = true) => Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: "BRL"
    }).format(value)
        .replaceAll(cipher ? "" : "R$", "")
        .trim();

    public static moneyAsNumber = (moneyFormat: string | any, cipher: boolean = true) =>
        Number(moneyFormat.replaceAll(cipher ? "" : "R$", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
            .trim());

    public static parseInt = (value?: any) => value === null ? null : Number.parseInt(value);

    public static parseFloat = (value?: any) => value === null ? null : Number.parseFloat(value);

    public static parseDate(value?: string | null): Date | null {
        try {
            if (this.isNullOrEmpty(value)) return null;

            let day = Number(value?.replaceAll('/', '',).substring(0, 2));
            let month = Number(value?.replaceAll('/', '',).substring(2, 4));
            let year = Number(value?.replaceAll('/', '',).substring(4, 8));

            return new Date(year, month, day);
        } catch (error) {
            return null;
        }
    }

    public static removeSpecialChars = (value?: string | null): string => value?.replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('[', '')
        .replaceAll(']', '')
        .replaceAll('/', '')
        .replaceAll('_', '')
        .replaceAll('-', '')
        .trim() ?? '';

    public static removeSpaces = (value?: string) => value?.replaceAll(' ', '').trim();

    public static sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    public static stringToEnum = <T>(value: string) => Number.parseInt(value) as T;
}