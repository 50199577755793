import { Badge } from "flowbite-react";
import { FC } from "react";
import { FormStateType } from "../../enums";
import { FormStateTagProps } from "./types";

export const FormStateTag : FC<FormStateTagProps> = (props) => {
    return (
        <div className="mt-1">
            <Badge color={props.tag === FormStateType.edit ? "warning" : "info"}>
                {props.tag === FormStateType.add ? "INSERINDO" : props.tag === FormStateType.edit ? "EDITANDO" : "CONSULTANDO"}
            </Badge>      
        </div>
    );
};