import { Textarea } from "flowbite-react";
import { FC, useState } from "react";
import { CodeLabel } from "../../../../components/CodeLabel";
import ModalForm from "../../../../components/ModalForm";
import { PopupReprovacaoOrdemProps } from "../CardAprovacoes/types";

export const PopupReprovacaoOrdem : FC<PopupReprovacaoOrdemProps> = (props) => {
    const [justificativa, setJustificativa] = props.justificativa;
    const [value, setValue] = useState<string>("");

    const handleButtonFecharClick = () => {
        setValue("");
        props.onClose?.call(this);
    }

    const handleGravarButtonClick = () => {
        setJustificativa(value);
        setValue("");

        props.onSave?.call(this, undefined);
        props.onClose?.call(this);
    }

    return (
        <ModalForm {...props} title="Reprovar Ordem"
            saveButtonTitle="Confirmar"
            onClose={handleButtonFecharClick}
            onSave={(e) => handleGravarButtonClick()}>
            <div className="grid grid-cols-12 p-3">
                <div className="col-span-12">
                    <CodeLabel className="mb-1" htmlFor="inputJustificativa" value="Motivo reprovação"/>
                    <Textarea name="inputJustificativa"
                        value={justificativa}
                        rows={5}
                        onChange={(e) => setJustificativa(e.currentTarget.value)}/>
                </div>
            </div>
        </ModalForm>
    );
};