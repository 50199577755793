import { Timeline } from "flowbite-react";
import { FC, useCallback, useEffect, useState } from "react";
import { ShowToast } from "../../../../../../components/CodeToast";
import { useSessionContext } from "../../../../../../contexts/SessionContext";
import useUserRole from "../../../../../../hooks/UserRoleHook";
import { ContratoMovimentacaoModel } from "../../../../../../models/ContratoMovimentoModel";
import { ContratoMovimentacaoServiceProps, ContratoMovimentacaoService } from "../../../../../../services/ContratoMovimentacaoService";
import CodeUtil from "../../../../../../util/CodeUtil";
import { UNKOWN_EXCEPTION } from "../../../../../../util/Constants";
import { CardExtratoTimelineProps } from "../../types";
import { CardExtratoItem } from "../CardExtratoItem";
import PngVoid from "../../../../../../images/void.png";
import { CardExtratoAccountBalance } from "../CardExtratoAccountBalance";

export const CardExtratoTimeline: FC<CardExtratoTimelineProps> = (props) => {
    const [timeline, setTimeline] = useState<string[]>([]);
    const [extrato, setExtrato] = useState<ContratoMovimentacaoModel[]>([]);
    const [dataLoaded, setDataLoaded] = props.dataLoaded;
    const currentSession = useSessionContext();
    const currentRole = useUserRole();

    const montarExtrato = useCallback(async () => {
        try {
            if (dataLoaded) return;

            setExtrato([]);
            setTimeline([]);
            setDataLoaded(true);

            //Periodo de 12 meses
            const now = new Date();
            now.setFullYear(now.getFullYear() - 1);

            const params: ContratoMovimentacaoServiceProps = {
                limit: 0,
                page: 1,
                tipoContrato: props.tipoContrato,
                tipoMovimento: props.tipoMovimento,
                statusContrato: ["Vigente", "Encerrado"],
                dataMovimentoInicio: now.toISOString(),
                dataMovimentoTermino: new Date().toISOString(),
                clienteId: currentRole === "CLIENTE" ? currentSession.session?.usuario?.clienteId : undefined
            };

            const response = await ContratoMovimentacaoService.get(params);
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) })
                return;
            }

            const data = response.data as ContratoMovimentacaoModel[];
            setExtrato(data);

            const _timeline = data.map(item => new Date(item.data).toLocaleDateString());
            const _arraySetTimeline = Array.from(new Set<string>(_timeline));
            setTimeline(_arraySetTimeline);
        } catch (error) {
            ShowToast({ message: UNKOWN_EXCEPTION })
            console.log(error);
        }
    }, [currentRole, currentSession, props, dataLoaded, setDataLoaded]);

    useEffect(() => {
        montarExtrato();
    }, [montarExtrato]);

    if (!props.show) return <></>;

    if (props.show && extrato.length === 0) {
        return (
            <div className="p-6 flex-col content-center justify-center text-center">
                <img alt="Void" src={PngVoid} width={100} height={100} className="w-48 h-48 mb-3" />
                <span className="text-sm font-normal text-slate-700">Está um pouco vazio por aqui.<br /></span>
            </div>
        );
    };

    return (
        <div className="my-0">
            <CardExtratoAccountBalance
                dataLoaded={props.dataLoaded}
                tipoContrato={props.tipoContrato} />

            <Timeline>
                {timeline.map(date =>
                    <Timeline.Item key={date} className="m-0 p-0">
                        <Timeline.Point />
                        <Timeline.Content className="space-y-0">
                            <Timeline.Time>{date}</Timeline.Time>
                            {extrato.filter(item => new Date(item.data).toLocaleDateString() === date)
                                .map(item =>
                                    <CardExtratoItem
                                        key={item.id}
                                        movimento={item}
                                        readonly={props.readonly} />)}
                        </Timeline.Content>
                    </Timeline.Item>
                )}
            </Timeline>
        </div >
    );
};