import { Tooltip } from "flowbite-react";
import { FC, MouseEvent } from "react";
import { FaFilePdf } from "react-icons/fa";
import { FiFile } from "react-icons/fi";
import { MdCancel } from "react-icons/md";
import { RiImageFill } from "react-icons/ri";
import styled from "styled-components";
import CodeUtil from "../../util/CodeUtil";

export type FileMiniatureProps = {
    label?: string;
    link?: string;
    createdDate: string;
    className?: string;
    showDeleteButton?: boolean;
    onDownload?: (e: MouseEvent<SVGAElement>) => void;
    onDelete?: (e: MouseEvent<SVGAElement>) => void;
};

export const FileMiniature: FC<FileMiniatureProps> = (props) => {

    const renderIcon = (): JSX.Element => {
        if (CodeUtil.isImageFile(props.label ?? "")) return <RiImageFill className="w-10 h-12 text-sky-300" />;
        if (props.label?.endsWith(".pdf")) return <FaFilePdf className="w-8 h-10 mb-2 text-sky-300" />;
        return <FiFile className="w-10 h-12 text-sky-300" />;
    }

    return (
        <Tooltip content={<>{props.label}<br />Criado em: {props.createdDate}</>} arrow={false}>
            <StyledFileMiniature className={props.showDeleteButton ?? false ? `p-1 ${props.className}` :
                `py-3 px-1 ${props.className}`}>

                {props.showDeleteButton ?? false ? <MdCancel onClick={props.onDelete} className="w-4 h-4 ml-auto text-red-500/80" /> : <></>}
                <a className="pb-3" href={props.link} rel="noreferrer" target="_blank">
                    <div className="flex justify-center pb-1">{renderIcon()}</div>
                    <p className="text-xs  truncate px-1">{props.label}</p>
                </a>
            </StyledFileMiniature>
        </Tooltip>
    );
};

const StyledFileMiniature = styled.div`
    height: 6rem;
    width: 5.25rem;
    border: 1px solid rgba(148, 163, 184, 0.25);
    border-radius: 0.365rem;
    cursor: pointer;
`

