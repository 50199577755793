import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { AxisOptions, Chart } from "react-charts";
import styled from "styled-components";
import { ShowToast } from "../../../../components/CodeToast";
import { useSessionContext } from "../../../../contexts/SessionContext";
import useUserRole from "../../../../hooks/UserRoleHook";
import { ExtratoRelatorioModel } from "../../../../models/ExtratoRelatorioModel";
import { ContratoMovimentacaoService, ContratoMovimentacaoServiceProps } from "../../../../services/ContratoMovimentacaoService";
import CodeUtil from "../../../../util/CodeUtil";
import { UNKOWN_EXCEPTION } from "../../../../util/Constants";
import { ChartHistoricoMovimentacaoProps } from "./types";

type SeriesData = {
    mesAno: string,
    value: number
}

type Series = {
    label: string,
    data: SeriesData[]
}

const emptySerieData: SeriesData = {
    mesAno: "",
    value: 0
}

export const ChartHistoricoMovimentacao: FC<ChartHistoricoMovimentacaoProps> = (props) => {
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const currentSession = useSessionContext();
    const currentRole = useUserRole();

    const [data, setData] = useState<Series[]>([]);

    const montarChart = useCallback((extrato: ExtratoRelatorioModel[]) => {
        let aportes: SeriesData[] = extrato.map<SeriesData>((item) => {
            return {
                mesAno: item.mesAno,
                value: item.totalAplicado
            }
        });

        let saquesAporte: SeriesData[] = extrato.map<SeriesData>((item) => {
            return {
                mesAno: item.mesAno,
                value: item.totalSaqueAplicacao < 0 ? item.totalSaqueAplicacao * -1 : item.totalSaqueAplicacao
            }
        });

        let rendimentos: SeriesData[] = extrato.map<SeriesData>((item) => {
            return {
                mesAno: item.mesAno,
                value: item.totalRendimento
            }
        });

        let saquesRendimento: SeriesData[] = extrato.map<SeriesData>((item) => {
            return {
                mesAno: item.mesAno,
                value: item.totalSaqueRendimento < 0 ? item.totalSaqueRendimento * -1 : item.totalSaqueRendimento
            }
        });

        setData([
            { label: "Aportes", data: aportes.length === 0 ? [emptySerieData] : aportes },
            { label: "Saques Aporte", data: saquesAporte.length === 0 ? [emptySerieData] : saquesAporte },
            { label: "Rendimentos", data: rendimentos.length === 0 ? [emptySerieData] : rendimentos },
            { label: "Saques Rendimento", data: saquesRendimento.length === 0 ? [emptySerieData] : saquesRendimento },
        ]);
    }, [setData]);

    const montarRelatorio = useCallback(async () => {
        try {
            if (dataLoaded) return;
            setDataLoaded(true);

            const now = new Date();
            now.setFullYear(now.getFullYear() - 1);

            const params: ContratoMovimentacaoServiceProps = {
                limit: 0,
                page: 1,
                dataMovimentoInicio: now.toISOString(),
                dataMovimentoTermino: new Date().toISOString(),
                statusContrato: ["Vigente", "Encerrado"],
                tipoContrato: props.tipoContrato,
                clienteId: currentRole === "CLIENTE" ? currentSession.session?.usuario?.clienteId : undefined
            };

            const response = await ContratoMovimentacaoService.montarRelatorioMensal(params);
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) })
                return;
            }

            montarChart(response.data);
        } catch (error) {
            ShowToast({ message: UNKOWN_EXCEPTION })
            console.log(error);
        }
    }, [currentRole, currentSession, props, dataLoaded, setDataLoaded, montarChart]);

    useEffect(() => {
        montarRelatorio();
    }, [montarRelatorio]);

    const xAxis = useMemo((): AxisOptions<SeriesData> => ({
        getValue: datum => datum.mesAno,
        scaleType: "band",
    }), []);

    const yAxis = useMemo((): AxisOptions<SeriesData>[] => ([{
        getValue: datum => datum.value,
        scaleType: "linear",
        stacked: false,
        min: 0,
    }]), []);

    return (
        <StyledCardChart>
            <div className="flex place-content-center">
                <h2 className="my-3 font-semibold">Extrato dos últimos 12 meses</h2>
            </div>

            <div className="chart m-3">
                <Chart options={{
                    data: data,
                    defaultColors: ["#3b82f6a9", "#ef4444a9", "#10b981a9", "#a855f7a9"],
                    primaryAxis: xAxis,
                    secondaryAxes: yAxis,
                    tooltip: true,

                }} />
            </div>
        </StyledCardChart>
    );
};

const StyledCardChart = styled.div`
    border-radius: 8px;
    border: 1px solid #cbd5e1;
    background-color: white;
    padding: 1.05rem;
    min-width: 50vw;
    max-width: 100%;
    height: 28rem;

    @media (max-width: 1024px) {
        width: 92vw;
        height: 28rem;
    }

    .chart {
        height: 22rem;
    }
`;