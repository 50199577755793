import { FluentValidator } from "../types";
import CodeUtil from "../util/CodeUtil";
import { BaseModel } from "./BaseModel";

export interface ClienteGrupoModel extends BaseModel {
    descricao: string;
    ativo: boolean;
    percentualAutoRendimento: number;
}

export const defaultClienteGrupo: ClienteGrupoModel = {
    descricao: '',
    ativo: false,
    percentualAutoRendimento: 0,
    isSelected: false
}

export const validateClienteGrupoModel = (model: ClienteGrupoModel): FluentValidator[] => {
    let result: FluentValidator[] = [];

    if (CodeUtil.isNullOrEmpty(model.descricao?.trim()))
        result.push({ field: 'descricao', message: 'O nome do grupo deve ser informado', isValid: false });

    if (model.percentualAutoRendimento === undefined || model.percentualAutoRendimento < 0)
        result.push({ field: 'percentualAutoRendimento', message: 'O % do auto rendimento deve ser maior ou igual a zero.', isValid: false });

    return result;
}