import { FC } from "react";
import styled from "styled-components";
import { DashboardCardProps } from "./types";

export const DashboardCard: FC<DashboardCardProps> = (props) => {
    return (
        <StyledCard className={props.className}>
            <div className="flex items-center title">
                {props.header}
            </div>

            {props.empty ?
                <StyledCardEmpty>
                    {props.emptyData}
                </StyledCardEmpty> :
                <>
                    <StyledCardChildren>
                        {props.children}
                    </StyledCardChildren>

                    <StyledCardFooter>
                        {props.footer}
                    </StyledCardFooter>
                </>}
        </StyledCard>
    );
};

const StyledCard = styled.div`
    border-radius: 8px;
    border: 1px solid #cbd5e1;
    background-color: white;
    padding: 1.05rem;
    width: 19rem;
    height: 28rem;

    .title {
        padding-bottom: 0.55rem;
        border-bottom: 1px solid #cbd5e1;
    }
`;

const StyledCardChildren = styled.div`
    padding-top: 1.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    height: 20.5rem;
    overflow-y: auto;
`

const StyledCardEmpty = styled.div`
    height: 24rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledCardFooter = styled.div`
    position: relative;
    bottom: 0;
`