import styled from "styled-components";
import { FlatButtonProps } from "./types";

const FlatButton : React.FC<FlatButtonProps> = (props) => {
    if (props.isDestructive) {
        return ( 
            <StyledDangerFlatButton onClick={props.disabled ? undefined : props.onClick} type="button" disabled={props.disabled} {...props}>
                <span>{props.label}</span> {props.children}
            </StyledDangerFlatButton>
        )
    }
    
    return ( 
        <StyledFlatButton onClick={props.disabled ? undefined : props.onClick} type="button" disabled={props.disabled} {...props}>
            <span>{props.label}</span> {props.children}
        </StyledFlatButton>
    )
}

const StyledFlatButton = styled.button`
    border-radius: 0.5rem;
    border: none;
    outline: none;    
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    align-items: center;
    display: flex;
    text-transform: none;

    > span {
        font-weight: 500;
        text-align: center;
    }

    :hover {
        background-color: rgb(226 232 240);
    }

    :active, :focus {
        background-color: rgb(203 213 225);
    }

    :disabled {
        background-color: rgb(226 232 240);
        opacity: 0.5;
        cursor: no-drop
    }

`

const StyledDangerFlatButton = styled(StyledFlatButton)`

    :hover {
        background-color: rgb(251 213 213);
    }

    :active, :focus {
        background-color: rgb(240 82 82);
        color: white;
    }

    :disabled {
        background-color: rgb(226 232 240);
        opacity: 0.5;
        cursor: no-drop;
        color: inherit;
    }
`

export default FlatButton;