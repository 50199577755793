import { SessionProps } from "../contexts/types";

const defaultSession : SessionProps = {
    redirectPath: '/',
    rememberMe: false,
    signed: false
}

export class SessionManager {    
    public static getSession() : SessionProps {
        if (localStorage.getItem('@App:session') === null) {
            return defaultSession;
        }

        return JSON.parse(localStorage.getItem('@App:session') ?? '') as SessionProps;
    }

    public static setSession(session: SessionProps | undefined) : void {
        localStorage.setItem('@App:session', JSON.stringify(session));
    }

    public static clearSession() {
        let session =  this.getSession();
        if (session.usuario !== undefined)
            session.usuario.token = '';
        window.location.pathname = '/login';
    } 
}