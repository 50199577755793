import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { Label, Modal, TextInput } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { useInvestmentCalculatorContext } from '../../contexts/InvestmentCalculatorContext';
import { BaseModel } from '../../models/BaseModel';
import { CalculadoraInvestimentoModel, defaultCalculadoraInvestimentosModel, validateModel } from '../../models/CalculadoraInvestimentoModel';
import { CalculadoraInvestimentoService } from '../../services/CalculadoraInvestimentoService';
import ErrorBadge from '../ErrorBadge';
import { InvestmentCalculatorContent } from './Content';
import { InvestmentCalculatorFooter } from './Footer';
import { MoneyInput } from '../MoneyInput';
import CodeUtil from '../../util/CodeUtil';

export const InvestmentCalculator : React.FC = () => {

    const { isShowing, showCalculator } = useInvestmentCalculatorContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(isShowing);
    const [errors, setErrors] = useState<string[]>([]);
    const [model, setModel] = useState<CalculadoraInvestimentoModel>(defaultCalculadoraInvestimentosModel);
    const [results, setResults] = useState<BaseModel[]>([]);
    
    const closeModal = () => {
        setModel(defaultCalculadoraInvestimentosModel);
        setShowModal(false); 
        showCalculator(false);
        setResults([]);
    }

    const onCalculate = async () => {
        try {
            if (!validate())
                return;
            setIsLoading(true);

            let response = await CalculadoraInvestimentoService.calcular(model);
            if (!response.success) {
                setErrors(response.messages);
                return;
            }

            setResults(response.data);
        } catch (error: any) {
            setErrors(prev => [...prev, error]);
        } finally {
            setIsLoading(false);
        }
    }

    const onExport = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const header = ['MÊS/ANO', "R$ INVESTIMENTO", "R$ RENDIMENTOS", "R$ SALDO" ];
        const workSheet =  XLSX.utils.aoa_to_sheet([], { cellStyles: true });
        
        XLSX.utils.sheet_add_aoa(workSheet, [header], { origin: 'A1' });
        XLSX.utils.sheet_add_json(workSheet, results, { origin: 'A2', skipHeader: true });

        const workBook = { Sheets: { 'Plano de Investimentos': workSheet }, SheetNames: ['Plano de Investimentos'] };
        const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});

        FileSaver.saveAs(data, `plano_investimento_${model.meses}_meses` + fileExtension);
    }

    const validate = () : boolean => {
        try {
            setErrors([]);

            let result = validateModel(model);
            if (result.length > 0) {
                setErrors(prev => [...prev, ...result.map((item) => item.message)])
            }

            return errors.length === 0 && result.length === 0; 

        } catch (error: any) {
            setErrors(prev => [...prev, error]);
            return false;
        }
    }

    useEffect(() => {
        setShowModal(isShowing);
    }, [isShowing, setShowModal])

    return ( 
        <React.Fragment>
            <Modal show={showModal} size="5xl" onClose={ closeModal }> 
                <Modal.Header>Calculadora de Investimentos</Modal.Header>           

                <Modal.Body>
                    <div className="mb-5">
                        <ErrorBadge errors={errors} />
                    </div>

                    <div className="form-control grid grid-cols-12 gap-6 mt-5">
                        <div className='col-span-12 md:col-span-3'>
                            <div className="mb-1"><Label htmlFor="inputAporte" value="Aporte inicial:"/></div>
                            <MoneyInput id="inputAporte"
                                value={CodeUtil.moneyFormat(model.aporteInicial, false)} 
                                onChange={(_value, numberValue) => setModel({...model, aporteInicial: numberValue }) }/>
                        </div>

                        <div className='col-span-12 md:col-span-3'>
                            <div className="mb-1"><Label htmlFor="inputAporteMensal" value="Aporte mensal:"/></div>
                            <MoneyInput id="inputAporteMensal"
                                value={CodeUtil.moneyFormat(model.aporteMensal, false)} 
                                onChange={(_value, numberValue) => setModel({...model, aporteMensal: numberValue }) }/>
                        </div>
                        
                        <div className='col-span-12 md:col-span-3'>
                            <div className="mb-1"><Label htmlFor="inputTaxaJurosMensal" value="Taxa de juros mensal:"/></div>
                            <MoneyInput id="inputTaxaJurosMensal"
                                addon="%"
                                value={CodeUtil.moneyFormat(model.taxaJuros, false)} 
                                onChange={(_value, numberValue) => setModel({...model, taxaJuros: numberValue }) }/>
                        </div>                        

                        <div className='col-span-12 md:col-span-3'>
                            <div className="mb-1"><Label htmlFor="inputMeses" value="Meses:"/></div>
                            <TextInput id="inputMeses" maxLength={60} type="number" min={1} step={1} 
                                value={model.meses} 
                                onChange={(e) => setModel({...model, meses: Number(e.currentTarget.value) }) }
                                placeholder="12" />
                        </div>                        
                    </div>

                    <div className='overflow-y-auto'>
                        <InvestmentCalculatorContent model={model} data={results} />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className='flex ml-auto space-x-2'>
                        <InvestmentCalculatorFooter isLoading={isLoading} canPrint={results?.length > 0} 
                            onCalculate={onCalculate} 
                            onExport={onExport} />
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}