import { TipoMovimento, OrdemStatus } from "../enums";
import { AppConfig } from "../env.config";
import { ContratoTipo } from "../models/ContratoModel";
import { OrdemModel } from "../models/OrdemModel";
import CodeUtil from "../util/CodeUtil";
import { BaseService } from "./BaseService";
import { DefaultResponse, ServiceProps } from "./types";

const ENDPOINT = `${AppConfig.ApiURL}/ordem`;

export type OrdemServiceProps = {
    status?: OrdemStatus[],
    tipo?: TipoMovimento[],
    tipoContrato?: ContratoTipo[],
    completo?: boolean,
    dataExecucaoInicio?: string,
    dataExecucaoTermino?: string,
    clienteId?: number,
    contratoId?: number
} & ServiceProps;

export class OrdemService extends BaseService {
    public static async get(props: OrdemServiceProps = { limit: 100, page: 1 }): Promise<DefaultResponse<OrdemModel>> {
        let _url = `${ENDPOINT}?limit=${props.limit}&page=${props.page}`;

        props.status?.forEach(status => _url += `&status=${status}`);
        props.tipo?.forEach(tipo => _url += `&tipo=${tipo}`);
        props.tipoContrato?.forEach(tipoContrato => _url += `&tipoContrato=${tipoContrato}`);

        if (!CodeUtil.isNullOrEmpty(props.dataExecucaoInicio)) _url += `&dataExecucaoInicio=${props.dataExecucaoInicio}`;
        if (!CodeUtil.isNullOrEmpty(props.dataExecucaoInicio)) _url += `&dataExecucaoTermino=${props.dataExecucaoTermino}`;
        if (props.clienteId !== undefined) _url += `&clienteId=${props.clienteId}`;
        if (props.contratoId !== undefined) _url += `&contratoId=${props.contratoId}`;

        _url += `&completo=${props.completo ?? false}`;
        return await this.GET<OrdemModel>(_url);
    }

    public static async getId(id: number): Promise<DefaultResponse<OrdemModel>> {
        return await this.GET<OrdemModel>(`${ENDPOINT}/${id}`);
    }

    public static async getContratoId(contratoId: number): Promise<DefaultResponse<OrdemModel>> {
        return await this.GET<OrdemModel>(`${ENDPOINT}/contrato/${contratoId}`);
    }

    public static async add(model: OrdemModel, comprovante?: Blob | null): Promise<DefaultResponse<OrdemModel>> {

        const formData = new FormData();
        formData.append("ordem", JSON.stringify(model));

        if ((comprovante?.size ?? 0) > 0)
            formData.append("comprovante", comprovante!);

        return await this.POSTFORM<Promise<DefaultResponse<OrdemModel>>>(`${ENDPOINT}`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    }

    public static async update(id: number, model: unknown, comprovante?: Blob | null): Promise<DefaultResponse<OrdemModel>> {
        const formData = new FormData();
        formData.append("ordem", JSON.stringify(model));

        if ((comprovante?.size ?? 0) > 0)
            formData.append("comprovante", comprovante!);

        return await this.PATCHFORM<Promise<DefaultResponse<OrdemModel>>>(`${ENDPOINT}/${id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    }

    public static async delete(id: number): Promise<DefaultResponse<OrdemModel>> {
        return await this.DELETE(`${ENDPOINT}/${id}`);
    }
}