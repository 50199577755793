export type DefaultResponse<T = any> = {
    data?: T[] | [] | any,
    messages: string[],
    success: boolean,
    pagination: Pagination
}

export interface ServiceProps {
    limit: number,
    page: number
}

export type Pagination = {
    limit: number,
    page: number,
    pageCount: number,
    recordCount: number,
}

export function AsDefaultResponse<T>(message: string, dataObject: T[] | []) : DefaultResponse<T> {
    return { 
        data: dataObject,
        messages: [ message ],
        success: false,
        pagination: {
            limit: 100,
            page: 1,
            pageCount:1,
            recordCount: 1 
        }
    }
}  

export type DefaultCEPResponse = {
    cep?: string;
    logradouro?: string;
    complemento?: string;
    bairro?: string;
    localidade?: string;
    uf?: string;
    ibge?: string;
    gia?: string;
    ddd?: string;
    siafi?: string;
}