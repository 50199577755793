import { UsuarioModel } from "../models/UsuarioModel";
import { AuthViewModel } from "../viewmodels/AuthViewModel";
import { AppConfig } from "../env.config";
import axios, { AxiosError } from "axios";
import { AsDefaultResponse, DefaultResponse } from "./types";
import { SessionManager } from "../util/SessionManager";

const ENDPOINT= `${AppConfig.ApiURL}/oauth`;

export class OAuthService {
    public static async auth(vm: AuthViewModel) : Promise<DefaultResponse<UsuarioModel>> {
        try {
            var response = (await axios.post(ENDPOINT, vm)).data as DefaultResponse<UsuarioModel>;
            if (response.success) {
                axios.defaults.headers.Authorization = `Bearer ${response.data![0].token}`;

                let usuario = response.data![0] as UsuarioModel; 
                SessionManager.setSession({
                    signed: true, 
                    usuario: usuario, 
                    redirectPath: "/home",
                    rememberMe: vm.rememberMe
                });                        
            }

            return response;

        } catch (error: any) {
            let axiosError = (error as AxiosError);
            if (axiosError.response?.data === null || axiosError.response?.data === undefined) {
                return AsDefaultResponse((error as AxiosError).message, []);
            }
            
            return axiosError.response!.data as DefaultResponse<UsuarioModel>;
        }
    }

    public static getSessionUser() : UsuarioModel | undefined {
        let session = SessionManager.getSession();
        return session === null ? undefined : session.usuario;
    }

    public static logout() : void {
        let session = SessionManager.getSession();
        session.signed = false;
        session.redirectPath = "/home";
    
        if (session.rememberMe && Boolean(session.usuario)){
            session.usuario!.token = undefined;
        }
                
        if (!session.rememberMe) {
            session.usuario = undefined;
        }

        SessionManager.setSession(session);
    } 
}

export default OAuthService;