import { FC } from "react";
import styled from "styled-components";
import { CodeLabelProps } from "./types";

export const CodeLabel: FC<CodeLabelProps> = (props) => {
    if (props.isLink) {
        return (
            <div className={props.className}>
                <StyledLinkLabel onClick={props.onClick} htmlFor={props.htmlFor}>{props.value}</StyledLinkLabel>
            </div>
        );
    }

    if (props.isDanger) {
        return (
            <div className={props.className}>
                <StyledDangerLabel onClick={props.onClick} htmlFor={props.htmlFor}>{props.value}</StyledDangerLabel>
            </div>
        );        
    }

    return (
        <div className={props.className}>
            <StyledLabel onClick={props.onClick} htmlFor={props.htmlFor}>{props.value}</StyledLabel>
        </div>
    );
};

const StyledLabel = styled.label`
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
    font-weight: 500;
`

const StyledLinkLabel = styled(StyledLabel)`
    cursor: pointer;
    color: rgb(26, 86, 219);

    :hover {
        color: rgb(30, 66, 159);
    }
`

const StyledDangerLabel = styled(StyledLabel)`
    cursor: pointer;
    color: rgb(240, 82, 82);

    :hover {
        color: rgb(200, 30, 30);
    }
`