import { Tooltip } from "flowbite-react";
import { FC, useCallback, useEffect, useState } from "react";
import { ShowToast } from "../../../../../../components/CodeToast";
import { useAccountBalanceContext } from "../../../../../../contexts/AccountBallanceContext";
import { useSessionContext } from "../../../../../../contexts/SessionContext";
import useUserRole from "../../../../../../hooks/UserRoleHook";
import { ContratoSaldoModel } from "../../../../../../models/ContratoSaldoModel";
import { ContratoService } from "../../../../../../services/ContratoService";
import CodeUtil from "../../../../../../util/CodeUtil";
import { UNKOWN_EXCEPTION } from "../../../../../../util/Constants";
import { CardExtratoAccountBalanceProps } from "../../types";

export const CardExtratoAccountBalance: FC<CardExtratoAccountBalanceProps> = (props) => {
    const { showAccountBalance } = useAccountBalanceContext();
    const currentSession = useSessionContext();
    const currentRole = useUserRole();

    const [loaded, setLoaded] = useState<boolean>(false);
    const [contratoSaldo, setContratoSaldo] = useState<ContratoSaldoModel>({ totalAplicado: 0, totalRendimento: 0, saldo: 0 });
    const [currentContextState, setCurrentContextState] = useState<boolean>(showAccountBalance);

    const atualizarSaldo = useCallback(async () => {

        try {
            if (loaded) return;
            setLoaded(true);

            let response = await ContratoService.getSaldoContrato({
                clienteId: currentRole === "CLIENTE" ? currentSession.session?.usuario?.clienteId : undefined,
                tipoContrato: props.tipoContrato,
                limit: 0,
                page: 1
            });

            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) })
                return;
            }

            setContratoSaldo(response.data);
        } catch (error) {
            ShowToast({ message: UNKOWN_EXCEPTION })
            console.log(error);
        }

    }, [loaded, currentSession, currentRole, props, setLoaded, setContratoSaldo]);


    const checkContextToogled = useCallback(async () => {
        if (currentContextState !== showAccountBalance) {
            setLoaded(false);
            setCurrentContextState(showAccountBalance);
        }
    }, [setLoaded, setCurrentContextState, currentContextState, showAccountBalance]);

    useEffect(() => {
        checkContextToogled();
        atualizarSaldo();
    }, [checkContextToogled, atualizarSaldo])

    return (
        <div className="flex flex-col mb-5 cursor-help">
            <span className="text-xs text-gray-600">Saldo:</span>

            <div className="-mb-0.5">
                <Tooltip arrow={false} content={(
                    <div>Aportes: {CodeUtil.moneyFormat(contratoSaldo.totalAplicado)}
                        <br />Rendimentos: {CodeUtil.moneyFormat(contratoSaldo.totalRendimento)}
                    </div>)}>
                    {showAccountBalance ?
                        <span className="font-bold -mb-3">
                            {CodeUtil.moneyFormat(contratoSaldo.saldo)}
                        </span> :
                        <div className='rounded bg-slate-200 h-5 w-24'></div>}
                </Tooltip>
            </div>
        </div>
    );
};