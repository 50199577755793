import { Dispatch, SetStateAction } from "react";
import { FormStateType } from "../enums";

export type FluentValidator = {
    field: string;
    message: string;
    isValid?: boolean;
}

export const findValidationField = (validation: FluentValidator[], field: string): FluentValidator => {
    let filterResult = validation.filter(item => item.field === field);
    return filterResult.length === 0 ? { field: '', isValid: true, message: '' } : filterResult[0];
}


export type SearchCTAProps<T> = {
    modelState: [T, Dispatch<SetStateAction<T>>];
    onAdd?: () => void;
    onEdit?: () => void;
    onView?: () => void;
    onDelete?: () => void;
    children?: JSX.Element | JSX.Element[];
}

export type ModalProps = {
    state: FormStateType;
    show: boolean;
    id?: number;
}

export type ModelState = "ADDED" | "UPDATED" | "DELETED" | "UNCHANGED";

export type ResponsePagination = {
    pageCount: number,
    pageIndex: number
}