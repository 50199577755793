import React, { } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CacheProvider } from 'rest-hooks';
import { AccountBalanceContextProvider } from './contexts/AccountBallanceContext';
import { InvestmentCalculatorProvider } from './contexts/InvestmentCalculatorContext';
import { SessionContextProvider } from './contexts/SessionContext';
import { SidebarAccountContextProvider } from './contexts/SidebarAccountContext';
import './index.css';
import { App } from './pages/App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CacheProvider>
        <SessionContextProvider>
          <AccountBalanceContextProvider>
            <InvestmentCalculatorProvider>
              <SidebarAccountContextProvider>
                <App />
              </SidebarAccountContextProvider>
            </InvestmentCalculatorProvider>
          </AccountBalanceContextProvider>
        </SessionContextProvider>
      </CacheProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
