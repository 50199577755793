import { FC } from "react";
import { RiAttachmentLine } from "react-icons/ri";
import { useSessionContext } from "../../../contexts/SessionContext";
import { ROUTE_CUSTOMERS_ATTACHMENTS } from "../../../util/AppRoutes";
import { SessionManager } from "../../../util/SessionManager";
import { SideBarItem } from "../Item";
import { SidebarProps } from "../types";

export const SidebarCustomerItems: FC<SidebarProps> = (props) => {
    const sessionContext = useSessionContext();
    const onSidebarItemClick = (event?: () => void) => {
        SessionManager.setSession({ ...SessionManager.getSession(), hiddenSidebar: true });
        sessionContext.refreshSession();

        event?.call(this);
        props.onItemClick?.call(this);
    }

    return (
        <>
            <SideBarItem onClick={onSidebarItemClick} to={ROUTE_CUSTOMERS_ATTACHMENTS} label="Anexos"
                icon={<RiAttachmentLine className="w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900" />} />
        </>
    );
};