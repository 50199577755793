import { createContext, useContext, useState } from "react";
import { InvestmentCalculatorProviderProps, InvestmentCalculatorType } from "./types";

const InvestmentCalculatorContext = createContext<InvestmentCalculatorType>({isShowing: false, showCalculator: (state) => {} });
export const useInvestmentCalculatorContext = () => useContext(InvestmentCalculatorContext) as InvestmentCalculatorType;

export const InvestmentCalculatorProvider : React.FC<InvestmentCalculatorProviderProps> = ({children}) => {
    const [showCalculator, setShowCalculator] = useState<boolean>(false);
    const doShowCalculator = (state: boolean) => {
        setShowCalculator(state);
    }


    return (
        <InvestmentCalculatorContext.Provider value={{ isShowing: showCalculator, showCalculator: doShowCalculator }} >
            {children}
        </InvestmentCalculatorContext.Provider>
    )
}

export default InvestmentCalculatorProvider;