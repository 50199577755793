import { Button, Modal, Spinner } from "flowbite-react";
import { FC } from "react";
import styled from "styled-components";
import { FormStateType } from "../../enums";
import FlatButton from "../FlatButton";
import { FormStateTag } from "../FormStateTag";
import { ModalFormProps } from "./types";

export const ModalForm: FC<ModalFormProps> = (props) => {

    if (!props.show) return <></>

    return (
        <form action="" method="post">
            <Modal size={props.size} show={props.show} onClose={props.onClose}>
                {props.hideHeader ? <></> :
                    <Modal.Header>
                        <span className="text-lg font-semibold text-left text-gray-900">{props.title}</span>
                    </Modal.Header>}

                <div>
                    <StyledModalBody>
                        {props.children}
                    </StyledModalBody>
                </div>

                <footer className="p-3 border-t-2 flex justify-center items-center">
                    <div className="mr-auto">
                        <FormStateTag tag={props.state} />
                    </div>

                    <div className="inline-flex space-x-2 ml-auto">
                        <FlatButton onClick={props.onClose} label="Fechar" />
                        {props.state === FormStateType.view ? <></> :
                            <Button type="button" gradientDuoTone="cyanToBlue" size="sm" onClick={(e) => props.onSave?.call(this, e)}>
                                <div className={(props.isSaving ?? false) ? "mr-3 text-white" : "text-white"}>
                                    <Spinner size="sm" light={true} hidden={!(props.isSaving ?? false)} />
                                </div>
                                {props.saveButtonTitle ?? 'Gravar'}
                            </Button>
                        }
                    </div>
                </footer>
            </Modal>
        </form>
    );
};

const StyledModalBody = styled.div`
    overflow-y: auto;
    max-height: 75vh;
    padding-left: 2px;
    padding-right: 2px;
`;

export default ModalForm;