import { Button, Spinner, TextInput } from "flowbite-react";
import { FC, useState } from "react";
import { useSessionContext } from "../../contexts/SessionContext";
import { useSidebarAccountContext } from "../../contexts/SidebarAccountContext";
import { PerfilModel } from "../../models/PerfilModel";
import { defaultUsuario, UsuarioModel } from "../../models/UsuarioModel";
import { UsuarioService } from "../../services/UsuarioService";
import { findValidationField, FluentValidator } from "../../types";
import CodeUtil from "../../util/CodeUtil";
import { UNKOWN_EXCEPTION } from "../../util/Constants";
import { SessionManager } from "../../util/SessionManager";
import { CodeLabel } from "../CodeLabel";
import { ShowToast } from "../CodeToast";
import FlatButton from "../FlatButton";
import { ProfilePicture } from "../ProfilePicture";

export const SidebarAccount: FC = () => {
    const { sidebarAccountHidden, setSidebarAccountHidden } = useSidebarAccountContext();
    const { session, refreshSession } = useSessionContext();

    const [sessionUser, setSessionUser] = useState<PerfilModel>(session?.usuario ?? defaultUsuario);
    const [validationErrors, setValidationErrors] = useState<FluentValidator[]>([]);
    const [profilePicture, setProfilePicture] = useState<File | undefined | null>();
    const [saving, setSaving] = useState<boolean>(false);

    const handleCancelarClick = () => {
        setSessionUser({
            ...sessionUser, ...(session?.usuario ?? defaultUsuario),
            senhaAtual: "", novaSenha: "", novaSenhaConfirmacao: "",
            foto: (session?.usuario ?? defaultUsuario).foto
        });
        setSidebarAccountHidden(true);
        setValidationErrors([]);
    }

    const handleAtualizarClick = async () => {
        if (!validarPerfil()) return;
        await atualizarPerfil();
    }

    const validarPerfil = () => {
        const errors: FluentValidator[] = [];

        if (CodeUtil.isNullOrEmpty(sessionUser.nome)) errors.push({ field: "nome", message: "O nome do usuário deve ser preenchido", isValid: false });
        if (CodeUtil.isNullOrEmpty(sessionUser.nomeUsuario)) errors.push({ field: "nomeUsuario", message: "O nome do usuário deve ser preenchido", isValid: false });
        if (!CodeUtil.isValidEmail(sessionUser.email)) errors.push({ field: "email", message: "O e-mail não é um e-mail válido.", isValid: false });
        if (CodeUtil.isNullOrEmpty(sessionUser.senhaAtual)) errors.push({ field: "senhaAtual", message: "A senha atual deve ser preenchida.", isValid: false });

        const novaSenha = sessionUser.novaSenha?.trim() ?? "";
        const novaSenhaConfirmacao = sessionUser.novaSenhaConfirmacao?.trim() ?? "";
        if (!CodeUtil.isNullOrEmpty(novaSenha) && (novaSenha.localeCompare(novaSenhaConfirmacao) === -1))
            errors.push({ ...validationErrors, field: "novaSenhaConfirmacao", message: "As senhas não conferem.", isValid: false });

        setValidationErrors(errors);
        return errors.length === 0;
    }

    const atualizarPerfil = async () => {
        try {
            setSaving(true);
            var response = await UsuarioService.atualizarPerfil(sessionUser, profilePicture);
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                return;
            }

            const perfil = response.data as UsuarioModel;
            const currentSession = SessionManager.getSession();

            SessionManager.setSession({
                ...currentSession,
                usuario: {
                    ...currentSession.usuario!,
                    nome: perfil.nome,
                    sobrenome: perfil.sobrenome,
                    nomeUsuario: perfil.nomeUsuario,
                    email: perfil.email,
                    foto: perfil.foto
                }
            });

            setSessionUser({
                ...sessionUser, ...perfil,
                senhaAtual: "", novaSenha: "", novaSenhaConfirmacao: "",
            });

            refreshSession();
            setSidebarAccountHidden(true);
        } catch (error) {
            console.log(error);
            ShowToast({ message: UNKOWN_EXCEPTION });
        } finally {
            setSaving(false);
        }
    };

    const handleSelectProfileImage = (file: File | null, fileUrl?: string) => {
        setSessionUser({ ...sessionUser, foto: fileUrl });
        setProfilePicture(file);
    }

    return (
        <div className={`${sidebarAccountHidden ? "hidden -z-50" : "fixed h-screen w-screen z-50"}`}>
            <div className="bg-black/30 fixed h-screen w-screen z-40 transition-transform" onClickCapture={() => setSidebarAccountHidden(true)} />

            <div className="shadow-sm overflow-y-auto bg-white fixed w-72 h-screen z-50 right-0 top-0 transition-transform">
                <ProfilePicture img={sessionUser.foto} onSelectImage={handleSelectProfileImage} />

                <div className="grid grid-cols-12 px-5 space-y-3">
                    <div className="form-control col-span-12">
                        <CodeLabel className="mb-1" value="Nome:" htmlFor="inputNome" />
                        <TextInput name="inputNome"
                            required={true}
                            type={"text"}
                            value={sessionUser?.nome}
                            helperText={findValidationField(validationErrors, "nome").message}
                            color={findValidationField(validationErrors, "nome").isValid ? "gray" : "failure"}
                            onChange={(e) => setSessionUser({ ...sessionUser, nome: e.currentTarget.value })} />
                    </div>

                    <div className="form-control col-span-12">
                        <CodeLabel className="mb-1" value="Sobrenome:" htmlFor="inputSobrenome" />
                        <TextInput name="inputSobrenome"
                            required={true}
                            type={"text"}
                            value={sessionUser?.sobrenome ?? ""}
                            onChange={(e) => setSessionUser({ ...sessionUser, sobrenome: e.currentTarget.value })} />
                    </div>


                    <div className="form-control col-span-12">
                        <CodeLabel className="mb-1" value="Nome de usuário:" htmlFor="inputNomeUsuario" />
                        <TextInput name="inputNomeUsuario"
                            required={true}
                            type={"text"}
                            value={sessionUser?.nomeUsuario}
                            helperText={findValidationField(validationErrors, "nomeUsuario").message}
                            color={findValidationField(validationErrors, "nomeUsuario").isValid ? "gray" : "failure"}
                            onChange={(e) => setSessionUser({ ...sessionUser, nomeUsuario: e.currentTarget.value })} />
                    </div>

                    <div className="form-control col-span-12">
                        <CodeLabel className="mb-1" value="E-mail:" htmlFor="inputEmail" />
                        <TextInput name="inputEmail"
                            type={"email"}
                            required={true}
                            value={sessionUser?.email}
                            helperText={findValidationField(validationErrors, "email").message}
                            color={findValidationField(validationErrors, "email").isValid ? "gray" : "failure"}
                            onChange={(e) => setSessionUser({ ...sessionUser, email: e.currentTarget.value })} />
                    </div>

                    <div className="form-control col-span-12">
                        <CodeLabel className="mb-1" value="Senha atual:" htmlFor="inputSenhaAtual" />
                        <TextInput name="inputSenhaAtual"
                            type={"password"}
                            required={true}
                            value={sessionUser?.senhaAtual}
                            helperText={findValidationField(validationErrors, "senhaAtual").message}
                            color={findValidationField(validationErrors, "senhaAtual").isValid ? "gray" : "failure"}
                            onChange={(e) => setSessionUser({ ...sessionUser, senhaAtual: e.currentTarget.value })} />
                    </div>

                    <div className="col-span-12 form-control my-6">
                        <br /><hr />
                    </div>

                    <div className="form-control col-span-12">
                        <CodeLabel className="mb-1" value="Nova senha:" htmlFor="inputSenha" />
                        <TextInput name="inputSenha"
                            type={"password"}
                            required={true}
                            value={sessionUser?.novaSenha}
                            helperText={findValidationField(validationErrors, "novaSenha").message}
                            color={findValidationField(validationErrors, "novaSenha").isValid ? "gray" : "failure"}
                            onChange={(e) => setSessionUser({ ...sessionUser, novaSenha: e.currentTarget.value })} />
                    </div>

                    <div className="form-control col-span-12">
                        <CodeLabel className="mb-1" value="Confirme a senha:" htmlFor="inputSenhaConfirmacao" />
                        <TextInput name="inputSenhaConfirmacao"
                            type={"password"}
                            required={true}
                            value={sessionUser?.novaSenhaConfirmacao}
                            helperText={findValidationField(validationErrors, "novaSenhaConfirmacao").message}
                            color={findValidationField(validationErrors, "novaSenhaConfirmacao").isValid ? "gray" : "failure"}
                            onChange={(e) => setSessionUser({ ...sessionUser, novaSenhaConfirmacao: e.currentTarget.value })} />
                    </div>

                    <div className="col-span-12 form-control my-6">
                        <br /><hr />
                    </div>

                    <div className="col-span-12 flex ml-auto space-x-2">
                        <Button disabled={saving} size={"sm"} gradientDuoTone="cyanToBlue" onClick={handleAtualizarClick}>
                            <span className="flex place-items-center justify-content-center space-x-1.5">
                                <Spinner size={"sm"} color="ligth" hidden={!saving} />
                                <span>Atualizar</span>
                            </span>
                        </Button>
                        <FlatButton label="Cancelar" onClick={handleCancelarClick} />
                    </div>
                </div>
            </div>
        </div >
    );
};